import { formatDate, formatNumber } from '@angular/common';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import {
  IntentOptions,
  WebIntent,
} from '@awesome-cordova-plugins/web-intent/ngx';
import { AlertController, Platform } from '@ionic/angular';
import cloneDeep from 'lodash-es/cloneDeep';
import round from 'lodash-es/round';
import { timeout } from 'rxjs/operators';

import { PRroSaleItem } from '../../p-rro/fsco/models/p-rro-sale-item.model';
import { PresaleProduct } from '../../presales/presale/presale-product.model';
import { SaleProduct } from '../../sales/sale/sale-product.model';
import { LogsService } from '../../settings/logs/logs.service';
import {
  DEFAULT_DPS_PRRO_CHECK_URL,
  DEFAULT_MAX_SALE_AMOUNT,
} from '../../settings/settings.const';
import {
  DEFAULT_REQUEST_CODE,
  TOAST_TITLE,
} from '../../settings/terminals/terminals.const';
import { Product } from '../../shop/products/product.model';
import { FULL_DATETIME_FORMAT } from '../constants/date.const';
import {
  SETTINGS_ONLINE_CHECK,
  TERMINAL_SERVICE_MESSAGE,
} from '../constants/events.const';
import { WEIGHT_AMOUNT } from '../constants/product.const';
import { Measurement } from '../enum/measurement.enum';
import { WeightProductMeasurement } from '../enum/weight-product-measurement.enum';
import { IErrorToastOptions } from '../interfaces/error-toast-options.interface';
import { IError } from '../interfaces/error.interface';
import { IWebIntentResult } from '../interfaces/web-intent-result.interface';
import { SyncResult } from '../models/sync-result.model';

import { CachedDataService } from './cached-data.service';
import { Events } from './events.service';
import { ToastService } from './toast.service';

const DEAFAULT_TIMEOUT = 3 * 1000;

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  MAX_SALE_AMOUNT = DEFAULT_MAX_SALE_AMOUNT;

  constructor(
    private http: HttpClient,
    private events: Events,
    private alertCtrl: AlertController,
    private platform: Platform,
    private appAvailability: AppAvailability,
    private market: Market,
    private webIntent: WebIntent,
    private cachedDataService: CachedDataService,
    private toastService: ToastService,
    private logsService: LogsService,
  ) {
    const appSettings = this.cachedDataService.getAppSettings();

    if (appSettings?.maxSaleAmount != null) {
      this.MAX_SALE_AMOUNT = Number(appSettings.maxSaleAmount);
    }
  }

  async isOnline(): Promise<boolean> {
    const isOnline = await this.http
      .get('/settings/network-state')
      .pipe(timeout(DEAFAULT_TIMEOUT))
      .toPromise()
      .then((_) => true)
      .catch((_) => false);

    this.events.publish(SETTINGS_ONLINE_CHECK, isOnline);

    return isOnline;
  }

  isAndroidApp(): boolean {
    return this.platform.is('cordova') && this.platform.is('android');
  }

  isDesktop(): boolean {
    return (
      !this.platform.is('cordova') &&
      !this.platform.is('android') &&
      !this.platform.is('ios')
    );
  }

  getRoundSum(totalPayment: number): number {
    let totalRound = 0;

    let lastDigit = Math.round(Math.fround(totalPayment * 100)) % 10;
    const sign = Math.sign(lastDigit);

    lastDigit = Math.abs(lastDigit);

    if (lastDigit > 0 && lastDigit < 5) {
      totalRound -= lastDigit / 100;
    } else if (lastDigit >= 5 && lastDigit <= 9) {
      totalRound += (10 - lastDigit) / 100;
    } else {
      totalRound = 0;
    }

    return sign * totalRound;
  }

  getQRText(
    docTaxNumber: string,
    docDate: Date,
    docSum: number,
    prroFiscalNumber?: number,
  ): string {
    const appSettings = this.cachedDataService.getAppSettings();

    return new HttpRequest(
      'GET',
      appSettings.dpsPRroCheckUrl ?? DEFAULT_DPS_PRRO_CHECK_URL,
      null,
      {
        params: new HttpParams()
          .append('date', formatDate(docDate, 'yyyyMMdd', 'uk_UA'))
          .append('time', formatDate(docDate, 'HHmmss', 'uk_UA'))
          .append('id', docTaxNumber)
          .append('sm', docSum.toFixed(2))
          .append('fn', (prroFiscalNumber ?? '').toString()),
      },
    ).urlWithParams;
  }

  getParsedError(problem: any): IError {
    // Get best available error message
    const parsedError: IError = {
      message: problem.message
        ? (problem.message as string)
        : problem.toString(),
    };

    if (problem.error) {
      this.processErrorMessage(problem, parsedError);
    }

    // Include HTTP status code
    if (problem.status != null) {
      parsedError.statusCode = problem.status;
    }

    if (problem.statusText != null) {
      parsedError.statusText = problem.statusText;
    }

    // Include stack trace
    if (problem.stack != null) {
      parsedError.stack = problem.stack;
    }

    if (problem.url != null) {
      parsedError.url = problem.url;
    }

    if (problem.name != null) {
      parsedError.name = problem.name;
    }

    this.logsService
      .add(this.getLogKey(parsedError), JSON.stringify(parsedError, null, 2), {
        addTimestamp: true,
      })
      .then()
      .catch();

    return parsedError;
  }

  async getPaymentPackageName(
    defaultPackageName: string,
    logKey: string,
  ): Promise<string> {
    const packageName = defaultPackageName;

    let packageInstalled = false;

    try {
      packageInstalled = await this.appAvailability.check(packageName);
    } catch (error) {
      await this.logsService.add(
        logKey,
        `Перевірка встановлення: ${JSON.stringify(error)}`,
        { addTimestamp: true },
      );
    }

    if (!packageInstalled) {
      await this.logsService.add(logKey, 'Платіжний додаток не встановлений', {
        addTimestamp: true,
      });

      this.events.publish(TERMINAL_SERVICE_MESSAGE, {
        description: 'Встановіть платіжний додаток й повторіть спробу',
        error: true,
      });

      setTimeout(() => {
        this.market.open(packageName);
      }, 2500);

      return '';
    }

    return packageName;
  }

  async startActivityForResult(
    options: IntentOptions,
    logKey: string,
  ): Promise<IWebIntentResult | null> {
    const activity = (window as any).plugins.intentShim;

    try {
      const intent: IWebIntentResult =
        await this.webIntent.startActivityForResult(options);

      if (
        // RESULT_OK === -1
        intent.extras?.resultCode === activity.RESULT_OK &&
        intent.extras?.requestCode === DEFAULT_REQUEST_CODE
      ) {
        return intent;
      }

      await this.logsService.add(
        logKey,
        `Web Intent Alert:\n${JSON.stringify(intent)}`,
        { addTimestamp: true },
      );

      // Message for Merchant that transaction wasn't processed successful
      // RESULT_CANCELED === 0
      if (intent.extras?.resultCode === activity.RESULT_CANCELED) {
        this.toastService.presentWarning(
          TOAST_TITLE,
          'Виникла проблема з виконанням транзакції.\nПодробиці у логах',
        );

        return intent;
      }

      this.showErrorAlert({
        message: `
          [Web Intent Alert] Відповідь платіжного додатку:<br>
          ${JSON.stringify(intent, null, 2)}
        `,
      });
    } catch (problem) {
      await this.logsService.add(
        logKey,
        `Web Intent Error:\n${JSON.stringify(problem)}`,
        { addTimestamp: true },
      );

      this.showErrorAlert({
        message: `
          [Web Intent Error] Не вдалося активувати платіжний додаток:<br>
          ${JSON.stringify(problem, null, 2)}
        `,
      });

      const parsedError = this.getParsedError(problem);

      this.events.publish(TERMINAL_SERVICE_MESSAGE, {
        error: true,
        description: parsedError.errorMessage,
      });
    }

    return null;
  }

  private getLogKey(parsedError: IError): string {
    if (
      parsedError.message.includes('cordova_not_available') ||
      parsedError.message.includes('plugin_not_installed')
    ) {
      return 'plugin';
    }

    if (
      parsedError.message.includes('/cover/') &&
      parsedError.statusCode === 404
    ) {
      return 'cover';
    }

    return 'error';
  }

  private processErrorMessage(problem: any, parsedError: IError): void {
    if (problem.error.message) {
      parsedError.errorMessage =
        typeof problem.error.message === 'string'
          ? problem.error.message
          : JSON.stringify(problem.error.message);
    } else if (problem.error instanceof ArrayBuffer) {
      parsedError.errorMessage = Buffer.from(problem.error)
        .toString()
        .replace(new RegExp('\n', 'g'), '');
    } else if (Array.isArray(problem.error)) {
      parsedError.errorMessage = JSON.stringify(problem.error);
    } else if (problem.error.errorDescription) {
      parsedError.errorMessage =
        typeof problem.error.errorDescription === 'string'
          ? problem.error.errorDescription
          : JSON.stringify(problem.error.errorDescription);
    }
  }

  showErrorToast(parsedError: IError, options: IErrorToastOptions = {}): void {
    const title = 'Помилка взаємодії з сервером';

    const message = `${parsedError.errorMessage ? 'Відповідь сервера: ' : ''}${
      parsedError.errorMessage ?? parsedError.message
    }`;

    const appSettings = this.cachedDataService.getAppSettings();

    const delay =
      parsedError.statusCode === 400 &&
      parsedError.message.includes(appSettings.dpsPRroUrl)
        ? 15000
        : 5000;

    const text = options.note
      ? options.isNoteAndMessage
        ? `${options.note}\n\n${message}`
        : options.note
      : message;

    if (options.isWarning) {
      this.toastService.presentWarning(title, text, delay);
    } else {
      this.toastService.presentError(title, text, delay);
    }
  }

  showErrorAlert(parsedError: IError): void {
    const shop = this.cachedDataService.getShop();
    const time = formatDate(new Date(), FULL_DATETIME_FORMAT, 'uk-UA');
    const session = this.cachedDataService.getSessionId();
    const info = `${time} ${session} ${shop?.id ?? ''}`;

    let alertMessage = `Помилка: <br>${parsedError.message}<br><br>`;

    if (parsedError.errorMessage != null) {
      alertMessage = `${alertMessage}Відповідь сервера: <br>${parsedError.errorMessage}<br><br>`;
    }

    if (parsedError.statusCode !== undefined) {
      alertMessage = `${alertMessage}Статус: <br>${parsedError.statusCode}`;
    }

    if (parsedError.statusText !== undefined) {
      alertMessage = `${alertMessage} ${parsedError.statusText}<br><br>`;
    } else {
      alertMessage = `${alertMessage}<br><br>`;
    }

    if (parsedError.stack != null) {
      alertMessage = `${alertMessage}Стек: <br>${parsedError.stack}`;
    }

    const useEmail = 'useEmail';

    this.alertCtrl
      .create({
        header: 'Помилка додатку',
        subHeader: `Будь ласка, відправте інформацію про цю ситуацію розробникам`,
        message: `${info}<br><br>${alertMessage}`,
        inputs: [
          {
            name: useEmail,
            type: 'checkbox',
            label: `Електронною поштою`,
            value: useEmail,
            checked: false,
          },
        ],
        buttons: [
          {
            text: 'Відправити',
            role: 'share',
            cssClass: 'secondary',
            handler: (options: string[]) => {
              this.logsService.send(`error`, alertMessage, {
                useEmail: options?.includes(useEmail),
                isDesktop: this.isDesktop(),
              });
            },
          },
          { text: 'Закрити', role: 'close' },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  cost(quantity: number, price: number): number {
    return round(round(quantity * 1000 * price * 100, 0) / 100000, 2);
  }

  calcWeightProductData(saleProduct: SaleProduct, weight: number): void {
    const productWeight = Math.round(weight);
    const quantity = productWeight ? productWeight / WEIGHT_AMOUNT : 0;

    saleProduct.quantity = round(quantity, 3);
    saleProduct.price = round(saleProduct.price, 2);
    saleProduct.cost = this.cost(
      productWeight / WEIGHT_AMOUNT,
      saleProduct.price,
    );

    saleProduct.fullPrice = round(saleProduct.fullPrice, 2);
    saleProduct.fullCost = this.cost(
      productWeight / WEIGHT_AMOUNT,
      saleProduct.fullPrice,
    );
  }

  addSyncError(problem: any, errors: string[]): void {
    const parsedError = this.getParsedError(problem);

    errors.push(parsedError.errorMessage ?? parsedError.message);
  }

  setResultMessage(result: SyncResult, errors: string[]): void {
    result.message = [...new Set(errors.map((error) => `- ${error}`))].join(
      '<br><br>',
    );

    if (result.success) {
      if (!result.warning) {
        result.message = `Синхронізовано ${result.itemsName}: ${result.totalItems}`;
      } else {
        result.message =
          `Синхронізовано ${result.syncedItems} з ${result.totalItems} ${result.itemsName}.<br><br>` +
          `У процесі синхронізації виникли наступні проблеми:<br><br>` +
          `${result.message}`;
      }
    } else {
      result.message =
        `Не вдалося виконати синхронізацію ${result.itemsName}:(<br><br>Будь ласка, спробуйте ще раз!` +
        `${result.message ? '<br><br>' : ''}` +
        `${
          result.message
            ? 'У процесі синхронізації виникли наступні проблеми:<br><br>'
            : ''
        }` +
        `${result.message}`;
    }
  }

  getStartSaleProduct(product: Product, quantity: number = 1): SaleProduct {
    const saleProduct = new SaleProduct();

    saleProduct.quantity = quantity;
    saleProduct.price = round(product.price, 2);
    saleProduct.fullPrice = round(product.price, 2);
    saleProduct.cost = round(quantity * product.price, 2);
    saleProduct.fullCost = round(quantity * product.price, 2);

    if (product.promoSchedules != null) {
      const promoSchedule = product.promoSchedules[0];

      promoSchedule.startedAt = new Date(promoSchedule.startedAt);
      promoSchedule.finishedAt = new Date(promoSchedule.finishedAt);

      const now = new Date();

      if (now > promoSchedule.startedAt && now < promoSchedule.finishedAt) {
        saleProduct.price = product.promoSchedules[0].price;
        saleProduct.cost = round(quantity * saleProduct.price, 2);

        saleProduct.personalDiscount = round(
          Math.max(
            0,
            saleProduct.quantity * (saleProduct.fullPrice - saleProduct.price),
          ),
          2,
        );

        saleProduct.discount = saleProduct.personalDiscount;
      }
    }

    saleProduct.productId = product.id;
    saleProduct.product = cloneDeep(product);

    return saleProduct;
  }

  getSaleProduct(invoiceItem: SaleProduct | PresaleProduct): SaleProduct {
    const product = new Product();

    product.name = this.productName(invoiceItem.product);
    product.amount = this.productMeasurement(invoiceItem.product);
    product.weightProduct = Boolean(invoiceItem.product.weightProduct);
    product.freeCupActive = Boolean(invoiceItem.product.freeCupActive);
    product.wholesalePrice = invoiceItem.product.wholesalePrice ?? 0;

    const saleProduct = new SaleProduct();

    saleProduct.productId = invoiceItem.product.id;
    saleProduct.productName = invoiceItem.productName;
    saleProduct.product = product;
    saleProduct.quantity = round(invoiceItem.quantity, 3);
    saleProduct.price = round(invoiceItem.price, 2);
    saleProduct.cost = round(invoiceItem.cost, 2);
    saleProduct.fullPrice = round(invoiceItem.fullPrice, 2);
    saleProduct.fullCost = round(invoiceItem.fullCost, 2);
    saleProduct.discount = round(invoiceItem.discount ?? 0, 2);

    if (invoiceItem.product.UKTZED != null && invoiceItem.product.UKTZED > '') {
      saleProduct.UKTZED = invoiceItem.product.UKTZED;
    } else if (invoiceItem.UKTZED != null && invoiceItem.UKTZED > '') {
      saleProduct.UKTZED = invoiceItem.UKTZED;
    }

    if (invoiceItem.product.excise != null) {
      saleProduct.exciseLetter = invoiceItem.product.excise.letter;
      saleProduct.excisePercent = invoiceItem.product.excise.percent;
      saleProduct.exciseAmount = PRroSaleItem.exciseAmount(
        invoiceItem.cost,
        invoiceItem.product.excise.percent,
      );
    } else if (
      invoiceItem.excisePercent != null &&
      invoiceItem.exciseAmount != null
    ) {
      saleProduct.exciseLetter = invoiceItem.exciseLetter;
      saleProduct.excisePercent = invoiceItem.excisePercent;
      saleProduct.exciseAmount = round(invoiceItem.exciseAmount, 2);
    }

    if (invoiceItem.exciseLabel != null) {
      saleProduct.exciseLabel = invoiceItem.exciseLabel;
    }

    if (invoiceItem.product.vat != null) {
      saleProduct.vatLetter = invoiceItem.product.vat.letter;
      saleProduct.vatPercent = invoiceItem.product.vat.percent;
      saleProduct.vatAmount = PRroSaleItem.vatAmount(
        invoiceItem.cost,
        invoiceItem.product.vat.percent,
        saleProduct.exciseAmount ?? 0,
      );
    } else if (
      invoiceItem.vatPercent != null &&
      invoiceItem.vatAmount != null
    ) {
      saleProduct.vatLetter = invoiceItem.vatLetter;
      saleProduct.vatPercent = invoiceItem.vatPercent;
      saleProduct.vatAmount = round(invoiceItem.vatAmount, 2);
    }

    if (invoiceItem.personalDiscount != null) {
      saleProduct.personalDiscount = round(invoiceItem.personalDiscount, 2);
    }

    return saleProduct;
  }

  productName(product: Product): string {
    if (
      product.weightProduct ||
      Object.values(Measurement)
        .map((value) => value.toString())
        .includes(product.amount.trim())
    ) {
      return product.name;
    }

    return `${product.name.trim()}, ${product.amount.trim()}`;
  }

  productMeasurement(product: Product): string {
    if (product.weightProduct) {
      switch (product.amount) {
        case `${WEIGHT_AMOUNT} ${WeightProductMeasurement.Gram}`:
          return Measurement.Kilogram;

        case `${WEIGHT_AMOUNT} ${WeightProductMeasurement.Millilitre}`:
          return Measurement.Litre;

        case `${WEIGHT_AMOUNT} ${WeightProductMeasurement.Millimetre}`:
          return Measurement.Metre;
      }
    }

    return Object.values(Measurement)
      .map((value) => value.toString())
      .includes(product.amount.trim())
      ? product.amount.trim()
      : Measurement.Unit;
  }

  productNameView(product: Product): string {
    let measurement = '';

    if (product.weightProduct) {
      switch (product.amount) {
        case Measurement.Kilogram:
          measurement = `${WEIGHT_AMOUNT} ${WeightProductMeasurement.Gram}`;
          break;

        case Measurement.Litre:
          measurement = `${WEIGHT_AMOUNT} ${WeightProductMeasurement.Millilitre}`;
          break;

        case Measurement.Metre:
          measurement = `${WEIGHT_AMOUNT} ${WeightProductMeasurement.Millimetre}`;
          break;
      }
    }

    return measurement > '' ? `${product.name}, ${measurement}` : product.name;
  }

  async cashLimit(amount: number): Promise<boolean> {
    if (amount > this.MAX_SALE_AMOUNT) {
      const zeroAlert = await this.alertCtrl.create({
        header: 'Помилка формування чеку',
        message: `Сума оплати готівкою не може перевищувати ${formatNumber(
          this.MAX_SALE_AMOUNT,
          'uk_UA',
          '1.2-2',
        )} грн`,
        buttons: [{ text: 'Закрити', role: 'close' }],
      });

      await zeroAlert.present();

      return true;
    }

    return false;
  }
}
