export const ESC_POS_INIT = '\x1B\x40';
export const ESC_POS_NEW_LINE = '\x0A';
export const ESC_POS_ALIGN_LEFT = '\x1b\x61\x00';
export const ESC_POS_ALIGN_CENTER = '\x1b\x61\x01';
export const ESC_POS_ALIGN_RIGHT = '\x1b\x61\x02';
export const ESC_POS_BOLD_ON = '\x1b\x45\x01';
export const ESC_POS_BOLD_OFF = '\x1b\x45\x00';
export const ESC_POS_CUT_PAPER_OLD = '\x1B\x69';
export const ESC_POS_OPEN_CASH_DRAWER_COMMAND_1 = '\x1B\x70\x00';

export const TAX_DATE_FORMAT = 'dd.MM.yyyy HH:mm:ss';
export const UAH = 'грн';
export const KOP = 'коп.';
export const WITHOUT_VAT = 'Без ПДВ';
export const THANKS = 'Дякуємо за покупку!';
export const NON_BREAKING_SPACE = '\u00A0';
export const PRRO_ONLINE_STATUS = 'онлайн';
export const POS_OPERATION_SALE = 'Продаж';
export const POS_OPERATION_RETURN = 'Повернення';

export const EDRPOU_CAPTION = 'ІД';
export const IPN_CAPTION = 'ПН';
export const UKTZED_CAPTION = 'Код УКТ ЗЕД';
export const EXCISE_LABEL_CAPTION = 'Акцизні марки';
export const TAX_VOLUME_CAPTION = 'Обіг';
export const EPZ_CAPTION = 'ЕПЗ';
export const PAYMENT_SYSTEM_CAPTION = 'ПЛАТІЖНА СИСТЕМА';
export const AUTH_CODE_CAPTION = 'Код авторизації';
export const RRN_CAPTION = 'RRN';
export const CHECK_NUMBER_CAPTION = 'Чек';
export const COST_SUM_CAPTION = 'Сума до знижки';
export const DISCOUNT_SUM_CAPTION = 'Знижка';
export const TOTAL_SUM_CAPTION = 'СУМА';
export const ROUND_SUM_CAPTION = 'Заокруглення';
export const CASH_PAYMENT_CAPTION = 'ГОТІВКА';
export const CASHLESS_PAYMENT_CAPTION = 'БЕЗГОТІВКОВА';
export const PAYMENT_SUM_CAPTION = 'До сплати';
export const PROVIDED_SUM_CAPTION = 'Сплачено';
export const REMAINS_SUM_CAPTION = 'Решта';
export const BONUS_BALANCE_CAPTION = 'Баланс бонусів';
export const BONUS_ADD_CAPTION = 'Нараховано бонусів';
export const CHECK_FISCAL_NUMBER_CAPTION = 'ФН';
export const CHECK_FISCAL_NUMBER_ALT_CAPTION = 'ЧЕК N°';
export const PRRO_ONLINE_STATUS_CAPTION = 'Режим роботи';
export const PRRO_FISCAL_NUMBER_CAPTION = 'ФН ПРРО';
export const RETURNED_CHECK_FISCAL_NUMBER_CAPTION = 'Повернення чеку з ФН';
export const RETURNED_CHECK_NUMBER_CAPTION = 'Повернення чеку #';
export const FISCAL_CHECK_CAPTION = 'ФІСКАЛЬНИЙ ЧЕК';
export const TEST_FISCAL_CHECK_CAPTION = 'ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ЧЕК';
export const X_REPORT_CAPTION = 'X-ЗВІТ';
export const Z_REPORT_CAPTION = 'Z-ЗВІТ';
export const REALIZ_SUM_CAPTION = 'Сума реалізації';
export const REALIZ_COUNT_CAPTION = 'Чеків реалізації';
export const RETURN_SUM_CAPTION = 'Сума повернення';
export const RETURN_COUNT_CAPTION = 'Чеків повернення';
export const SERVICE_INPUT_CAPTION = 'Службове внесення';
export const SERVICE_OUTPUT_CAPTION = 'Службова видача';
export const SERVICE_DOC_AMOUNT_CAPTION = 'Сума';
export const SHIFT_START_CAPTION = 'Початок зміни';
export const SHIFT_FINISH_CAPTION = 'Кінець зміни';
export const REVENUE_CAPTION = 'Виручка';
export const DEPOSIT_CAPTION = 'Внесення';
export const INCASATION_CAPTION = 'Інкасація';
export const FINANCE_EXPENSES_CAPTION = 'Витрати';
export const REFILL_EXPENSE_CAPTION = 'Витрати на прихід';
export const WASTE_RETURN_RECEIVED_CAPTION = 'Повернення приходу';
export const CASH_REMAIN_CAPTION = 'Залишок готівки в касі';
export const WASTE_CAPTION = 'Списання';
export const MOVE_CAPTION = 'Переміщення';
export const MOVE_IN_CAPTION = 'Переміщення з іншого магазину';
export const MOVE_STOCK_IN_CAPTION = 'Переміщення зі складу';
export const SHIFT_TOTALS_REPORT_CAPTION = 'Підсумки зміни';
export const LAST_DOC_LOCAL_NUMBER_CAPTION = 'ЛН останнього чека';
export const LAST_DOC_FISCL_NUMBER_CAPTION = 'ФН останнього чека';

export const WASTE_NUMBER_CAPTION = 'Накладна #__________ від';
export const WASTE_SELF_NAME_CAPTION = 'Відправник';
export const WASTE_SELF_RNOKPP_CAPTION = 'РНОКПП';
export const WASTE_PROVIDER_NAME_CAPTION = 'Отримувач';
export const WASTE_USER_NAME_CAPTION = 'Через';
export const WASTE_POA_CAPTION = 'Довіреність: сер.__ N______ від <__><__>';
export const WASTE_HEADER_NAME_CAPTION = 'Назва';
export const WASTE_HEADER_QUANTITY_CAPTION = 'К-сть';
export const WASTE_HEADER_NO_VAT_CAPTION = 'без ПДВ';
export const WASTE_HEADER_PRICE_CAPTION = 'Ціна';
export const WASTE_HEADER_SUM_CAPTION = 'Сума';
export const WASTE_TOTAL_SUM_CAPTION = 'Всього без ПДВ';
export const WASTE_TOTAL_COST_SUM_CAPTION = 'Всього собівартість без ПДВ';
export const WASTE_TOTAL_SALE_SUM_CAPTION = 'Всього у цінах продажу без ПДВ';
export const WASTE_TOTAL_VAT_CAPTION = 'ПДВ (___%)';
export const WASTE_TOTAL_SUM_WITH_VAT_CAPTION = 'Загальна сума з ПДВ';
export const WASTE_POSITION_COUNT_1_CAPTION = 'Всього';
export const WASTE_POSITION_COUNT_2_CAPTION = 'найменувань(ня)';
export const WASTE_SUMMARY_SUM_CAPTION = 'на суму';
export const WASTE_SUMMARY_VAT_CAPTION = 'в т.ч. ПДВ _____';
export const WASTE_DIRECTOR_SIGN_CAPTION = 'Директор';
export const WASTE_ACCOUNTANT_SIGN_CAPTION = 'Бухгалтер';
export const WASTE_SHOP_SIGN_CAPTION = 'Відпустив';
export const WASTE_PROVIDER_SIGN_CAPTION = 'Отримав';
export const WASTE_SALE_PRICE_MODE_NOTE =
  '* вище - собівартість, нижче - ціна продажу';

export const PRESALE_CREATED_AT_CAPTION = 'Замовлення від';
export const PRESALE_COMPLETE_BY_CAPTION = 'Дата і час видачі';
export const PRESALE_HEADER_NAME_CAPTION = 'Назва';
export const PRESALE_HEADER_QUANTITY_CAPTION = 'К-сть';
export const PRESALE_COMMENT_CAPTION = 'Коментар (адреса доставки)';
export const PRESALE_CLIENT_CAPTION = 'Замовник';

export const MOVE_NUMBER_CAPTION = 'Накладна переміщення від';
export const MOVE_SENDER_NAME_CAPTION = 'Відправник';
export const MOVE_RECIPIENT_NAME_CAPTION = 'Отримувач';
export const MOVE_HEADER_NAME_CAPTION = 'Назва';
export const MOVE_HEADER_QUANTITY_CAPTION = 'К-сть';
export const MOVE_HEADER_SUM_CAPTION = 'Сума';
export const MOVE_TOTAL_SUM_CAPTION = 'Всього';
export const MOVE_SENDER_SIGN_CAPTION = 'Видав';
export const MOVE_RECIPIENT_SIGN_CAPTION = 'Отримав';

export const REFILL_NUMBER_CAPTION = 'Накладна надходження від';
export const REFILL_PROVIDER_NAME_CAPTION = 'Постачальник';
export const REFILL_SHOP_NAME_CAPTION = 'Отримувач';
export const REFILL_USER_NAME_CAPTION = 'Користувач';
export const REFILL_HEADER_CAPTION = 'Оновлення цін на товари';
export const REFILL_HEADER_NAME_CAPTION = 'Назва';
export const REFILL_HEADER_QUANTITY_CAPTION = 'К-сть';
export const REFILL_HEADER_SUM_CAPTION = 'Сума';
export const REFILL_TOTAL_SUM_CAPTION = 'Всього';
export const REFILL_PAID_SUM_CAPTION = 'Сплачено';
export const REFILL_SENDER_SIGN_CAPTION = 'Видав';
export const REFILL_RECIPIENT_SIGN_CAPTION = 'Отримав';
