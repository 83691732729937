import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import orderBy from 'lodash-es/orderBy';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { Resource } from '../../abstract/resource';
import { ShiftIngredient } from '../../models/shift-ingredient.model';
import { Shift } from '../../models/shift.model';
import { CachedDataService } from '../cached-data.service';
import { ToastService } from '../toast.service';

const ORDER_BY = ['ingredient.category.position', 'ingredient.position'];

@Injectable({
  providedIn: 'root',
})
export class ShiftsService extends Resource<Shift> {
  private currentShift?: Shift | null;

  constructor(
    protected http: HttpClient,
    private cachedDataService: CachedDataService,
    private toastService: ToastService,
  ) {
    super(http, {
      path: '/shifts',
    });
  }

  getShift(fullReport: boolean = false): Observable<Shift | null> {
    let params = new HttpParams();

    params = params.append(
      'shopId',
      this.cachedDataService.getShopId().toString(),
    );

    params = params.append(fullReport ? 'fullReport' : 'shortReport', 'true');

    return this.http
      .get<Shift>(`${this.config.path}/current/app`, { params })
      .pipe(
        map((shift) => this.transformFromBackend(shift)),
        tap((shift) => {
          this.currentShift = shift;

          this.cachedDataService.setShift(this.currentShift);
        }),
      );
  }

  async getShiftReport(): Promise<Shift | null> {
    return this.getShift(true)
      .toPromise()
      .then((currentShift) => {
        if (currentShift == null) {
          this.toastService.presentWarning('Звіт за зміну', 'Зміна закрита');
        }

        return currentShift;
      })
      .catch(() => null);
  }

  getShiftLocal(): Observable<Shift | null> {
    this.currentShift = this.cachedDataService.getShift();

    return new Observable((observer) => {
      observer.next(this.currentShift);
      observer.complete();
    });
  }

  getShiftIngredients(): Observable<ShiftIngredient[]> {
    return this.http
      .get<ShiftIngredient[]>(`${this.config.path}/current/shift-ingredients`)
      .pipe(map((shiftIngredients) => orderBy(shiftIngredients, ORDER_BY)));
  }

  createRevision(): Observable<ShiftIngredient[]> {
    return this.http
      .patch<ShiftIngredient[]>(`${this.config.path}/current/revision`, {})
      .pipe(map((shiftIngredients) => orderBy(shiftIngredients, ORDER_BY)));
  }

  fillRevisionByZero(): Observable<ShiftIngredient[]> {
    return this.http
      .patch<ShiftIngredient[]>(`${this.config.path}/current/fill-zero`, {})
      .pipe(map((shiftIngredients) => orderBy(shiftIngredients, ORDER_BY)));
  }

  getCurrentShiftId(): number {
    return this.currentShift ? this.currentShift.id : null!;
  }

  getCurrentShift(): Shift {
    return this.currentShift ? this.currentShift : null!;
  }

  isOpened(): boolean {
    return Boolean(this.currentShift && !this.currentShift.closed);
  }

  isShiftUser(): boolean {
    const user = this.cachedDataService.getUser();

    if (!(this.currentShift && user)) {
      return false;
    }

    return Boolean(
      this.currentShift.userId === user.id ||
        this.currentShift.shiftUsers?.find(
          (userTotals) => userTotals.userId === user.id,
        ),
    );
  }

  open(): Observable<Shift> {
    const shift = new Shift();

    shift.shopId = this.cachedDataService.getShopId();
    shift.userId = this.cachedDataService.getUser().id;

    return super.create(shift).pipe(
      tap((createdShift) => {
        this.currentShift = createdShift;
        this.currentShift.createdAt = new Date(createdShift.createdAt);

        this.cachedDataService.setShift(this.currentShift);
      }),
    );
  }

  close(): Observable<Shift> {
    let params = new HttpParams();

    const shiftId = this.currentShift
      ? this.currentShift.id
      : this.cachedDataService.getShift().id;

    if (shiftId != null) {
      params = params.append('shiftId', shiftId.toString());
    }

    return super.update('current/close', {}, params).pipe(
      finalize(() => {
        this.currentShift = null;

        this.cachedDataService.setShift(this.currentShift);
      }),
    );
  }

  updateCurrentShiftIngredients(
    shiftIngredients: ShiftIngredient[],
  ): Observable<ShiftIngredient[]> {
    return this.http.patch<ShiftIngredient[]>(
      `${this.config.path}/current/shift-ingredients`,
      this.transformShiftIngredientsToBackend(shiftIngredients),
    );
  }

  updateCurrentShiftIngredient(
    id: number,
    shiftIngredient: ShiftIngredient,
  ): Observable<ShiftIngredient> {
    return this.http.patch<ShiftIngredient>(
      `${this.config.path}/current/shift-ingredients/${id}`,
      shiftIngredient,
    );
  }

  private transformShiftIngredientsToBackend(
    shiftIngredients: ShiftIngredient[],
  ): ShiftIngredient[] {
    const shiftIngredientsClone = cloneDeep(shiftIngredients);

    shiftIngredientsClone.forEach((shiftIngredient) => {
      if (shiftIngredient.startRealQuantity != null) {
        shiftIngredient.startRealQuantity =
          shiftIngredient.startRealQuantity !== '' &&
          shiftIngredient.startRealQuantity !== null
            ? Number(shiftIngredient.startRealQuantity)
            : null;
      }

      if (shiftIngredient.endRealQuantity != null) {
        shiftIngredient.endRealQuantity =
          shiftIngredient.endRealQuantity !== '' &&
          shiftIngredient.endRealQuantity != null
            ? Number(shiftIngredient.endRealQuantity)
            : null;
      }

      Reflect.deleteProperty(shiftIngredient, 'ingredient');
    });

    return shiftIngredientsClone;
  }

  private transformFromBackend(originalShift: Shift | null): Shift | null {
    if (!originalShift) {
      return originalShift;
    }

    const shift = new Shift();

    shift.id = originalShift.id;
    shift.closed = originalShift.closed;
    shift.shopId = originalShift.shopId;
    shift.userId = originalShift.userId;
    shift.totals = originalShift.totals;
    shift.shiftUsers = originalShift.shiftUsers;
    shift.verified = originalShift.verified;
    shift.lastShiftCashAmount = originalShift.lastShiftCashAmount;

    if (originalShift && originalShift.createdAt) {
      shift.createdAt = new Date(originalShift.createdAt);
    }

    if (originalShift && originalShift.closedAt) {
      shift.closedAt = new Date(originalShift.closedAt);
    }

    return shift;
  }
}
