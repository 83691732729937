import { v4 as uuid } from 'uuid';

export class PRroDocHead {
  shopId: number;
  userId: number;

  date: Date;
  localNumber: number;
  taxNumber: string;
  uId: string;
  cashier: string;

  testing: boolean;

  constructor(date?: Date) {
    if (date != null) {
      this.date = date;
    } else {
      this.date = new Date();
    }

    this.uId = uuid();
    this.cashier = '';
    this.testing = false;
  }
}
