import { PrinterConnectionMethod } from '../printer-connection-method.enum';
import { DEFAULT_PRINTER } from '../printer.const';

import { LabelPrinterSettings } from './label-printer-settings.interface';
import { IPrintLabelBarcode } from './types/print-label-barcode.interface';
import { IPrintLabelMargins } from './types/print-label-margins.interface';
import { IPrintLabelPaper } from './types/print-label-paper.interface';

export const PRINTER_URL = 'label-printer';
export const PRINTER_CLASS = 'принтер етикеток';

export const PAPER_23_X_14: IPrintLabelPaper = {
  width: 23,
  height: 14,
};

export const PAPER_40_X_25: IPrintLabelPaper = {
  width: 40,
  height: 25,
};

export const PAPER_60_X_40: IPrintLabelPaper = {
  width: 60,
  height: 40,
};

export const BARCODE_EAN_13: IPrintLabelBarcode = {
  name: 'ean13',
  title: 'EAN-13',
};

export const BARCODE_CODE128: IPrintLabelBarcode = {
  name: 'code128',
  title: 'CODE128',
};

export const DEFAULT_PAPER_FORMAT = PAPER_40_X_25;
export const DEFAULT_BARCODE_FORMAT = BARCODE_EAN_13;
export const DEFAULT_PDF_INSTEAD_PRINT = false;
export const DEFAULT_AUTO_PRINT_AFTER_SALE = false;
export const DEFAULT_MARGINS: IPrintLabelMargins = {
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
};

export const DEFAULT_SETTINGS: LabelPrinterSettings = {
  connectionMethod: PrinterConnectionMethod.None,
  printer: DEFAULT_PRINTER,
  ip: '',
  mac: '',
  paperFormat: DEFAULT_PAPER_FORMAT,
  barcodeFormat: DEFAULT_BARCODE_FORMAT,
  pdfInsteadPrint: DEFAULT_PDF_INSTEAD_PRINT,
  margins: DEFAULT_MARGINS,
  autoPrintAfterSale: DEFAULT_AUTO_PRINT_AFTER_SALE,
};

export const EAN_8_LENGTH = 8;
export const EAN_13_LENGTH = 13;

export const MAX_MARGIN = 10;
export const CHECK_LOGO_WIDTH = 420;
export const CHECK_LOGO_HEIGHT = 140;
