export const MIN_CURRENCY_VALUE = 0;
export const MIN_CURRENCY_VALUE_NOT_ZERO = 0.01;
export const MAX_CURRENCY_VALUE = 99999.99;

export const MIN_CURRENCY_SUM_VALUE = 0;
export const MAX_CURRENCY_SUM_VALUE = 999999.99;

export const MIN_QUANTITY_VALUE = 0.001;
export const MAX_QUANTITY_VALUE = 99999.999;

export const MIN_PERCENT_VALUE = 0;
export const MAX_PERCENT_VALUE = 100;

export const MIN_WEIGHT_VALUE = 1;
export const MAX_WEIGHT_VALUE = 99999;

export const MIN_STRING_LENGTH = 0;
export const MAX_STRING_LENGTH = 255;

export const MAX_PHONE_LENGTH = 13;

export const ONE_KOP = 0.01;

export const BARCODE_EAN_13 = 13;
export const BARCODE_EAN_8 = 8;
