import { Component, Input, OnInit } from '@angular/core';

import { IStorageUserSettings } from '../../../../core/interfaces/storage-user-setting.interface';
import { Shop } from '../../../../core/models/shop.model';
import { CachedDataService } from '../../../../core/services/cached-data.service';
import { PayFormName } from '../../../../p-rro/fsco/enums/pay-form-name.enum';
import { PRRO_NAME } from '../../../settings.const';
import {
  AUTH_CODE_CAPTION,
  BONUS_ADD_CAPTION,
  BONUS_BALANCE_CAPTION,
  CASH_PAYMENT_CAPTION,
  CASHLESS_PAYMENT_CAPTION,
  CHECK_FISCAL_NUMBER_ALT_CAPTION,
  CHECK_NUMBER_CAPTION,
  COST_SUM_CAPTION,
  DISCOUNT_SUM_CAPTION,
  EPZ_CAPTION,
  PAYMENT_SUM_CAPTION,
  PAYMENT_SYSTEM_CAPTION,
  PROVIDED_SUM_CAPTION,
  REMAINS_SUM_CAPTION,
  ROUND_SUM_CAPTION,
  RRN_CAPTION,
  THANKS,
  TOTAL_SUM_CAPTION,
} from '../../receipt/receipt.const';
import { PrintSale } from '../../receipt/types/print-sale.model';

@Component({
  selector: 'bk-view-sale',
  templateUrl: './view-sale.component.html',
  styleUrls: ['./view-sale.component.scss'],
})
export class ViewSaleComponent implements OnInit {
  @Input() sale: PrintSale;
  @Input() printShopLogoInReceipt: boolean;

  shop: Shop;
  userSettings: IStorageUserSettings;

  debtSumCaption = PayFormName.InDebt;
  cardSumCaption = PayFormName.Card;
  cardFormCaption = CASHLESS_PAYMENT_CAPTION;
  cashFormCaption = CASH_PAYMENT_CAPTION;
  epzCaption = EPZ_CAPTION;
  paymentSystemCaption = PAYMENT_SYSTEM_CAPTION;
  authCodeCaption = AUTH_CODE_CAPTION;
  rrnCaption = RRN_CAPTION;
  checkNumberCaption = CHECK_NUMBER_CAPTION;
  costSumCaption = COST_SUM_CAPTION;
  discountSumCaption = DISCOUNT_SUM_CAPTION;
  totalSumCaption = TOTAL_SUM_CAPTION;
  roundSumCaption = ROUND_SUM_CAPTION;
  paymentSumCaption = PAYMENT_SUM_CAPTION;
  providedSumCaption = PROVIDED_SUM_CAPTION;
  remainsSumCaption = REMAINS_SUM_CAPTION;
  bonusAddCaption = BONUS_ADD_CAPTION;
  bonusBalanceCaption = BONUS_BALANCE_CAPTION;
  thanks = THANKS;
  prroName = PRRO_NAME;

  get checkFiscalNumberRow(): string {
    return `${CHECK_FISCAL_NUMBER_ALT_CAPTION.replace('N°', '№')} ${
      this.sale.checkFiscalNumber
    }`;
  }

  constructor(private cachedDataService: CachedDataService) {}

  async ngOnInit(): Promise<void> {
    this.shop = this.cachedDataService.getShop();
    this.userSettings = this.cachedDataService.getUserSettings();
  }
}
