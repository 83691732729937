import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { IntentOptions } from '@awesome-cordova-plugins/web-intent/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

import {
  TERMINAL,
  TERMINAL_SERVICE_MESSAGE,
} from '../../../core/constants/events.const';
import { CachedDataService } from '../../../core/services/cached-data.service';
import { Events } from '../../../core/services/events.service';
import { ToastService } from '../../../core/services/toast.service';
import { UtilsService } from '../../../core/services/utils.service';
import { LogsService } from '../../logs/logs.service';
import { ITerminalServiceMessage } from '../interfaces/terminal-service-message.interface';
import { PrivatbankJsonMethod } from '../privatbank/json/enums/method.enum';
import { PrivatbankJsonServiceMessageTypeResponse } from '../privatbank/json/enums/service-message-type-response.enum';
import { PrivatbankJsonTxnType } from '../privatbank/json/enums/txn-type.enum';
import { TerminalStorageService } from '../terminal-storage.service';
import {
  DEFAULT_REQUEST_CODE,
  DEFAULT_TIMEOUT,
  PAYMENT_SYSTEM_MASTERCARD,
  PAYMENT_SYSTEM_VISA,
  TOAST_TITLE,
  TRANSACTION_TOKEN_ERROR,
} from '../terminals.const';

import { ITapXphoneIntentExtraCheck } from './interfaces/intent/extra-check.interface';
import { ITapXphoneIntentExtra } from './interfaces/intent/extra.interface';
import { ITapXphoneBatchRequest } from './interfaces/requests/batch-request.interface';
import { ITapXphoneConnectionIntentRequest } from './interfaces/requests/connection-intent-request.interface';
import { ITapXphonePaymentIntentRequest } from './interfaces/requests/payment-intent-request.interface';
import { ITapXphonePaymentIntentTransactionCancelRequest } from './interfaces/requests/payment-intent-transaction-cancel-request.interface';
import { ITapXphoneTokenDoRequestData } from './interfaces/requests/token-do-request-data.interface';
import { ITapXphoneCurrentBatchStatusResponse } from './interfaces/responses/current-batch-status-response.interface';
import { ITapXphonePedStatusResponse } from './interfaces/responses/ped-status-response.interface';
import { ITapXphoneTokenResponse } from './interfaces/responses/token-response.interface';
import { ITapXphoneTrGetDoResponse } from './interfaces/responses/tr-get-do-response.interface';
import { TapXphonePaymentIntentTransactionType } from './types/payment-intent-transaction-type.enum';
import { parseTapXphoneResult } from './utils/intent-result-status';
import { merchantDeviceStatus } from './utils/merchant-device-status';

const CONNECTION_TOKEN_ERROR = `Не вдалося отримати токен підключення`;
const UID_ERROR = 'Не вдалося отримати ідентифікатор банку';

const URL = '/tapxphone';
const LOG_KEY = 'tapXphone';
const ACQUIRER_ID_MONOBANK = 39;
const PACKAGE_NAME = 'by.iba.tapxphone';
const ACTIVITY = `ui.activities.MainActivity`;

const DEFAULT_DEVICE_SETUP_MODE = 3;
const DEFAULT_LOCALE = 'uk';

const RESULT_SUCCESS = '0';
const RESULT_INIT_SUCCESS = '909';

@Injectable({
  providedIn: 'root',
})
export class TapXphoneService {
  private sessionToken = '';

  constructor(
    protected http: HttpClient,
    private events: Events,
    private alertCtrl: AlertController,
    private utilsService: UtilsService,
    private toastService: ToastService,
    private logsService: LogsService,
    private cachedDataService: CachedDataService,
    private terminalStorageService: TerminalStorageService,
    private platform: Platform,
    private device: Device,
  ) {}

  private async log(message: string): Promise<void> {
    await this.logsService.add(LOG_KEY, message, { addTimestamp: true });
  }

  private defaultIntentOptions(packageName: string): IntentOptions {
    return {
      component: {
        package: packageName,
        class: `${packageName}.${ACTIVITY}`,
      },
      requestCode: DEFAULT_REQUEST_CODE,
      extras: {
        locale: DEFAULT_LOCALE,
        device_setup_mode: DEFAULT_DEVICE_SETUP_MODE,
        customization: JSON.stringify({
          backgroundColor: '#232323',
          logoColor: '#00d127',
          loadingIndicatorColor: '#eb1d39',
          loadingIndicatorSize: 48,
          versionFont: 'Roboto-Regular',
          versionFontColor: '#0080ba',
          versionFontSize: 16,
        }),
      },
    };
  }

  async transaction(
    deviceId: string,
    amount: number,
    rrn: string = '',
  ): Promise<void> {
    const packageName = await this.utilsService.getPaymentPackageName(
      PACKAGE_NAME,
      LOG_KEY,
    );

    if (packageName === '') {
      return;
    }

    const sessionResponse = await this.getSessionTokenDo(deviceId, amount, rrn);

    if (sessionResponse.token === '') {
      await this.log(TRANSACTION_TOKEN_ERROR);

      this.events.publish(TERMINAL_SERVICE_MESSAGE, {
        description: TRANSACTION_TOKEN_ERROR,
        error: true,
      });

      return;
    }

    const options = this.defaultIntentOptions(packageName);

    options.extras = {
      ...options.extras,
      uid: sessionResponse.uid,
      token: sessionResponse.token,
    };

    const webIntentResult = await this.utilsService.startActivityForResult(
      options,
      LOG_KEY,
    );

    await this.log(
      `${rrn > '' ? 'Refund' : 'Purchase'} Intent Result:\n${JSON.stringify(
        webIntentResult,
      )}`,
    );

    if (webIntentResult?.extras == null) {
      return;
    }

    const paymentAppData = webIntentResult.extras as ITapXphoneIntentExtra;

    if (
      paymentAppData.Result != null &&
      paymentAppData.Check != null &&
      paymentAppData.Result === RESULT_SUCCESS
    ) {
      // Main parse logic
      const check = JSON.parse(
        paymentAppData.Check,
      ) as ITapXphoneIntentExtraCheck;

      this.events.publish(`${TERMINAL}:${PrivatbankJsonMethod.Purchase}`, {
        method: PrivatbankJsonMethod.Purchase,
        step: 0,
        params: {
          amount: check.amt,
          approvalCode: check.auth_code,
          date: check.date_time.split(' ')[0].split('-').reverse().join('.'),
          invoiceNumber: check.trxid,
          issuerName: this.parsePaymentSystemName(check),
          merchant: check.pmt_terminal,
          pan: check.card_mask,
          responseCode: '0000',
          rrn: check.rrn,
          terminalId: check.pmt_terminal,
          time: check.date_time.split(' ')[1],
          signVerif: '0',
          txnType: this.parseOperationName(check),
          bankAcquirer: check.bank_owner,
          paymentSystem: check.applbl,
          subMerchant: check.unp,
        },
        error: false,
        errorDescription: '',
      });
    } else {
      // Message for Merchant that transaction wasn't processed successful
      const resultInfo = parseTapXphoneResult(
        paymentAppData.Result ?? '',
        paymentAppData.server_error,
      );

      this.events.publish(TERMINAL_SERVICE_MESSAGE, {
        description: `${resultInfo.description}\n${resultInfo.recommendation}`
          .replace(new RegExp('<br>', 'g'), '\n')
          .replace(new RegExp('<strong>', 'g'), '')
          .replace(new RegExp('</strong>', 'g'), ''),
        error: true,
      });
    }
  }

  async cancelTransaction(): Promise<void> {
    if (this.sessionToken === '') {
      this.toastService.presentWarning(
        TOAST_TITLE,
        'Невідомий токен сесії\nНеможливо виконати скасування транзакції',
      );

      return;
    }

    const response = await this.sendRequest<ITerminalServiceMessage>(
      'v1/payment-intent/transaction/cancel',
      {
        method: 'put',
        data: {
          token: this.sessionToken,
        },
      },
    );

    await this.log(`Cancel Transaction Response:\n${JSON.stringify(response)}`);

    if (response == null) {
      this.events.publish(TERMINAL_SERVICE_MESSAGE, {
        method: PrivatbankJsonMethod.ServiceMessage,
        step: 0,
        params: {
          msgType:
            PrivatbankJsonServiceMessageTypeResponse.interruptTransmitted,
        },
        error: false,
        errorDescription: '',
      });
    } else {
      this.events.publish(TERMINAL_SERVICE_MESSAGE, response);
    }
  }

  async transactionStatus(): Promise<void> {
    if (this.sessionToken === '') {
      this.toastService.presentWarning(
        TOAST_TITLE,
        'Невідомий токен сесії\nНеможливо перевірити статус транзакції',
      );

      return;
    }

    const response = await this.sendRequest<ITapXphoneTrGetDoResponse>(
      'token/tr_get.do',
      {
        method: 'post',
        data: {
          token: this.sessionToken,
        },
      },
    );

    await this.log(`Transaction Status Response:\n${JSON.stringify(response)}`);

    if (response == null) {
      return;
    }

    this.utilsService.showErrorAlert({
      message: `
      Transaction Status:<br>
        ${JSON.stringify(response, null, 2)}
      `,
    });
  }

  async initDevice(deviceId: string): Promise<boolean> {
    const packageName = await this.utilsService.getPaymentPackageName(
      PACKAGE_NAME,
      LOG_KEY,
    );

    if (packageName === '') {
      return false;
    }

    const connectionResponse = await this.getConnectionToken(deviceId);

    if (connectionResponse.token === '') {
      await this.log(CONNECTION_TOKEN_ERROR);

      this.events.publish(TERMINAL_SERVICE_MESSAGE, {
        description: CONNECTION_TOKEN_ERROR,
        error: true,
      });

      return false;
    }

    const uid = await this.getUID();

    if (uid === '') {
      this.toastService.presentError(TOAST_TITLE, UID_ERROR);

      return false;
    }

    const options = this.defaultIntentOptions(packageName);

    options.extras = {
      ...options.extras,
      uid,
      connection_token: connectionResponse.token,
    };

    const webIntentResult = await this.utilsService.startActivityForResult(
      options,
      LOG_KEY,
    );

    await this.log(`Init Intent Result:\n${JSON.stringify(webIntentResult)}`);

    if (webIntentResult?.extras == null) {
      return false;
    }

    const paymentAppData = webIntentResult.extras as ITapXphoneIntentExtra;

    if (
      paymentAppData.Result != null &&
      (paymentAppData.Result === RESULT_SUCCESS ||
        paymentAppData.Result === RESULT_INIT_SUCCESS)
    ) {
      const pedStatus = await this.getPedStatus(paymentAppData.device_id);

      if (pedStatus != null) {
        this.saveBindedDevice(pedStatus).subscribe(() => {
          this.toastService.present(
            TOAST_TITLE,
            'Платіжний додаток активовано',
          );
        });
      } else {
        this.toastService.presentWarning(TOAST_TITLE, 'Повторіть спробу');
      }

      return true;
    }

    // Message for Merchant that transaction wasn't processed successful
    const resultInfo = parseTapXphoneResult(
      paymentAppData.Result ?? '',
      paymentAppData.server_error,
    );

    this.utilsService.showErrorAlert({
      message: `
        ${resultInfo.description}<br>
        ${resultInfo.recommendation}<br><br>
        [Web Intent] Відповідь на активацію:<br>
        ${JSON.stringify(webIntentResult, null, 2)}
      `,
    });

    return false;
  }

  async batch(
    operation: 'status' | 'close',
  ): Promise<ITapXphoneCurrentBatchStatusResponse | null> {
    const settings = await this.terminalStorageService.getSettings();

    if (settings.tapXphoneDeviceId === '') {
      this.toastService.presentWarning(
        TOAST_TITLE,
        'Невідомий ідентифікатор платіжного додатку',
      );

      return null;
    }

    const response =
      await this.sendRequest<ITapXphoneCurrentBatchStatusResponse>(
        `v1/current-batch/${operation}`,
        {
          method: 'put',
          data: {
            terminal_id_type: 'merchant_device_id',
            terminal_id: settings.tapXphoneDeviceId,
          },
        },
      );

    await this.log(`Batch ${operation} response:\n${JSON.stringify(response)}`);

    if (operation === 'status' && response != null) {
      switch (response.code) {
        case 1:
          response.description =
            'Бізнес-день активний. Дозволено приймати транзакції';
          break;

        case 4:
          response.description =
            'Бізнес-день закритий. Попередня звірка пройшла успішно';
          break;

        case 6:
          response.description = 'Бізнес-день у процесі закриття';
          break;

        default:
          break;
      }
    }

    return response;
  }

  async showDeviceStatus(
    deviceStatus: ITapXphoneIntentExtra | null,
  ): Promise<void> {
    if (deviceStatus == null) {
      this.toastService.presentWarning(
        TOAST_TITLE,
        'Не вдалося отримати інформації з платіжного додатку',
      );

      return;
    }

    const appStatus = merchantDeviceStatus(
      deviceStatus?.merchant_device_status_code ?? 0,
    );

    const alert = await this.alertCtrl.create({
      header: `Статус платіжного додатку`,
      message: `
        Пристрій:<br><strong>${deviceStatus.device_id}</strong><br><br>
        <strong>${appStatus.desctiption}</strong><br>
        ${appStatus.recommendation}`,
      buttons: [{ text: 'Закрити', role: 'close' }],
    });

    await alert.present();
  }

  async checkDeviceStatus(): Promise<void> {
    const deviceStatus = await this.getDeviceStatus();

    if (deviceStatus == null) {
      this.toastService.presentWarning(
        TOAST_TITLE,
        'Не вдалося отримати інформації з платіжного додатку',
      );

      return;
    }

    const appStatus = merchantDeviceStatus(
      deviceStatus?.merchant_device_status_code ?? 0,
    );

    const pedStatus = await this.getPedStatus(deviceStatus.device_id);
    const serverStatus = merchantDeviceStatus(
      pedStatus?.merchant_device_status ?? 0,
    );

    const serverStatusMessage = pedStatus
      ? `<br><br>Сервер:<br>${serverStatus.desctiption}<br>${serverStatus.recommendation}`
      : '';

    this.events.publish(
      `${TERMINAL}:${PrivatbankJsonServiceMessageTypeResponse.identify}`,
      {
        method: PrivatbankJsonMethod.ServiceMessage,
        step: 0,
        params: {
          msgType: PrivatbankJsonServiceMessageTypeResponse.identify,
          result: 'true',
          vendor: `Термінал:<br><strong>${
            pedStatus?.merchant_terminal_id ?? 'Невідомий'
          }</strong><br><br>Пристрій:<br><strong>${
            deviceStatus.device_id
          }</strong><br><br>`,
          model: `${serverStatusMessage ? 'Додаток:<br>' : ''}${
            appStatus.desctiption
          }<br>${appStatus.recommendation}${serverStatusMessage}`,
        },
        error: false,
        errorDescription: '',
      },
    );
  }

  async getDeviceStatus(): Promise<ITapXphoneIntentExtra | null> {
    const packageName = await this.utilsService.getPaymentPackageName(
      PACKAGE_NAME,
      LOG_KEY,
    );

    if (packageName === '') {
      return null;
    }

    const uid = await this.getUID();

    if (uid === '') {
      this.toastService.presentError(TOAST_TITLE, UID_ERROR);

      return null;
    }

    const options = this.defaultIntentOptions(packageName);

    options.extras = {
      ...options.extras,
      uid,
      intent_type: 'status',
    };

    const webIntentResult = await this.utilsService.startActivityForResult(
      options,
      LOG_KEY,
    );

    await this.log(`Status Intent Result:\n${JSON.stringify(webIntentResult)}`);

    if (webIntentResult?.extras == null) {
      return null;
    }

    const paymentAppData = webIntentResult.extras as ITapXphoneIntentExtra;

    if (paymentAppData.device_id == null) {
      this.toastService.presentWarning(
        TOAST_TITLE,
        'Не вдалося отримати інформацію про ідентифікатор пристрою з платіжного додатку',
      );

      return null;
    }

    paymentAppData.device_id = `${
      paymentAppData.device_id.split('_')[0]
    }_${ACQUIRER_ID_MONOBANK}`;

    const settings = await this.terminalStorageService.getSettings();

    settings.tapXphoneDeviceId = paymentAppData.device_id;

    await this.terminalStorageService.setSettings(settings);

    return paymentAppData;
  }

  async getPedStatus(
    deviceId: string,
  ): Promise<ITapXphonePedStatusResponse | null> {
    const response = await this.sendRequest<ITapXphonePedStatusResponse>(
      'v1/ped/status',
      {
        method: 'put',
        data: {
          terminal_id_type: 'merchant_device_id',
          terminal_id: deviceId,
        },
      },
    );

    await this.log(`PED Status Response:\n${JSON.stringify(response)}`);

    return response;
  }

  confirmLicenseAgreement(): Observable<void> {
    return this.http.patch<void>(`${URL}/license-agreement`, null);
  }

  saveBindedDevice(data: ITapXphonePedStatusResponse): Observable<void> {
    return this.http.patch<void>(`${URL}/device`, data, {
      params: { shopId: this.cachedDataService.getShopId().toString() },
    });
  }

  /**
   * Get payment token
   */
  async getSessionTokenDo(
    deviceId: string,
    amount: number,
    rrn: string,
  ): Promise<ITapXphoneTokenResponse> {
    this.sessionToken = '';

    const response = await this.sendRequest<ITapXphoneTokenResponse>(
      'token/token.do',
      {
        method: 'post',
        data: {
          deviceId,
          rrn,
          amount: amount.toFixed(2),
          operation: rrn > '' ? 'RRN' : 'contactless',
        },
      },
    );

    await this.log(
      `Session Token Do Response:\n${response == null ? 'failed' : 'success'}`,
    );

    this.sessionToken = response?.token ?? '';

    return response ?? { token: '', uid: '' };
  }

  /**
   * Get payment token
   */
  async getSessionToken(
    deviceId: string,
    transactionType: TapXphonePaymentIntentTransactionType,
    amount: number,
    rrn: string,
  ): Promise<ITapXphoneTokenResponse> {
    this.sessionToken = '';

    const response = await this.sendRequest<ITapXphoneTokenResponse>(
      'v1/payment-intent',
      {
        method: 'put',
        data: {
          deviceId,
          trn_amount: amount.toFixed(2),
          trn_type: transactionType,
          trn_host_rrn: rrn,
        },
      },
    );

    await this.log(
      `Session Token Response:\n${response == null ? 'failed' : 'success'}`,
    );

    this.sessionToken = response?.token ?? '';

    return response ?? { token: '', uid: '' };
  }

  /**
   * Get connection token
   */
  private async getConnectionToken(
    deviceId: string,
  ): Promise<ITapXphoneTokenResponse> {
    const response = await this.sendRequest<ITapXphoneTokenResponse>(
      'v1/payment-terminal/connection-intent',
      {
        method: 'put',
        data: {
          merchant_device_id: deviceId,
          deviceInfo: `${this.device.platform} ${this.device.version}, ${
            this.device.manufacturer
          } ${
            this.device.model
          }, ${this.platform.width()}x${this.platform.height()} (${this.platform.platforms()})`,
        },
      },
    );

    await this.log(
      `Connection Token Response:\n${response == null ? 'failed' : 'success'}`,
    );

    return response ?? { token: '', uid: '' };
  }

  private async getUID(): Promise<string> {
    const response = await this.sendRequest<{ uid: string }>('uid', {
      method: 'get',
    });

    await this.log(`UID Response:\n${response == null ? 'failed' : 'success'}`);

    // Список терміналів, якщо терміналів у клієнта немає, список може бути пустим, відсутнім або null
    return response?.uid ?? '';
  }

  private async sendRequest<T>(
    path: string,
    options: {
      method: string;
      data?:
        | ITapXphonePaymentIntentRequest
        | ITapXphonePaymentIntentTransactionCancelRequest
        | ITapXphoneConnectionIntentRequest
        | ITapXphoneTokenDoRequestData
        | ITapXphoneBatchRequest;
    },
  ): Promise<T | null> {
    const shop = this.cachedDataService.getShop();

    return await this.http
      .request<T>(options.method, `${URL}/${path}`, {
        params: { shopId: shop.id.toString() },
        body: options.data,
        observe: 'response',
      })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .toPromise<HttpResponse<T>>()
      .then((response) => {
        if (
          path.includes('/current-batch/close') &&
          response.status === 204 &&
          response.body == null
        ) {
          return {
            code: response.status,
            description: 'Успішно',
          } as unknown as T;
        }

        return response.body;
      })
      .catch(async (problem) => {
        await this.log(`Request Error (${path}):\n${JSON.stringify(problem)}`);

        const parsedError = this.utilsService.getParsedError(problem);

        if (
          path.includes('/current-batch') &&
          problem.error.code != null &&
          problem.error.message != null
        ) {
          return {
            code: problem.error.code,
            description: problem.error.message,
          } as unknown as T;
        }

        if (path.includes('/transaction/cancel')) {
          return {
            error: true,
            description: parsedError.errorMessage,
          } as unknown as T;
        }

        this.utilsService.showErrorToast(parsedError);

        return null;
      });
  }

  private parsePaymentSystemName(check: ITapXphoneIntentExtraCheck): string {
    return check.applbl
      ? check.applbl
      : check.card_mask[0] === '4'
      ? PAYMENT_SYSTEM_VISA
      : check.card_mask[0] === '5'
      ? PAYMENT_SYSTEM_MASTERCARD
      : check.bank_owner;
  }

  private parseOperationName(
    check: ITapXphoneIntentExtraCheck,
  ): PrivatbankJsonTxnType {
    return check.pmt_name === 'Оплата'
      ? PrivatbankJsonTxnType.undefined
      : check.pmt_name.includes('Повернення')
      ? PrivatbankJsonTxnType.Refund
      : PrivatbankJsonTxnType.Purchase;
  }
}
