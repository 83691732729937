import { formatNumber } from '@angular/common';
import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AlertController,
  IonDatetime,
  IonSearchbar,
  ModalController,
} from '@ionic/angular';
import cloneDeep from 'lodash-es/cloneDeep';
import remove from 'lodash-es/remove';
import round from 'lodash-es/round';
import { DateTime } from 'luxon';

import { ClientBonus } from '../../clients/client-bonus.model';
import { ClientFreeCup } from '../../clients/client-free-cup.model';
import { Client } from '../../clients/client.model';
import { ClientsService } from '../../clients/clients.service';
import { UA_MONTHS_GENITIVE } from '../../core/constants/date.const';
import { BROADCAST_SALE_CALCULATION } from '../../core/constants/events.const';
import {
  MAX_CURRENCY_SUM_VALUE,
  MAX_PERCENT_VALUE,
  MAX_STRING_LENGTH,
  ONE_KOP,
} from '../../core/constants/form-validations.const';
import { SALE_CALCULATION_DIALOG_ID } from '../../core/constants/modal-controller.const';
import { ShopPRroMode } from '../../core/enum/shop-p-rro-mode.enum';
import { IStorageUserSettings } from '../../core/interfaces/storage-user-setting.interface';
import { Shop } from '../../core/models/shop.model';
import { Tariff } from '../../core/models/tariff.model';
import { User } from '../../core/models/user.model';
import { BroadcastService } from '../../core/services/broadcast.service';
import { CachedDataService } from '../../core/services/cached-data.service';
import { LoadingService } from '../../core/services/loading.service';
import { ShopsService } from '../../core/services/resources/shops.service';
import { TariffService } from '../../core/services/resources/tariff.service';
import { ToastService } from '../../core/services/toast.service';
import { UtilsService } from '../../core/services/utils.service';
import { CheckDocumentSubType } from '../../p-rro/fsco/enums/check-document-sub-type.enum';
import { PayFormCode } from '../../p-rro/fsco/enums/pay-form-code.enum';
import { PRroSaleItem } from '../../p-rro/fsco/models/p-rro-sale-item.model';
import { Presale } from '../../presales/presale/presale.model';
import { PresalesService } from '../../presales/presales.service';
import { SalePayment } from '../../sales/sale/sale-payment.model';
import { SaleProduct } from '../../sales/sale/sale-product.model';
import { Sale } from '../../sales/sale/sale.model';
import { SalesService } from '../../sales/sales.service';

import { DeliveryDialog } from './delivery/delivery.dialog';
import { SaleCalculationFocus } from './sale-calculation-focus.enum';

const OTHER_BUTTONS_WIDTH = 175;
const DOT_KEY = ',';
const BACKSPACE_KEY = '⌫';

@Component({
  selector: 'bk-sale-calculation-dialog',
  templateUrl: './sale-calculation.dialog.html',
  styleUrls: ['./sale-calculation.dialog.scss'],
})
export class SaleCalculationDialog implements OnInit, OnDestroy {
  @ViewChild(IonSearchbar) searchbar: IonSearchbar;
  @ViewChild(IonDatetime) dateTime: IonDatetime;

  @Input() saleProducts: SaleProduct[];
  @Input() presale?: Presale;
  @Input() saleInDebt?: Sale;
  @Input() saleWithDiscount?: boolean;
  @Input() clientBonus?: ClientBonus;
  @Input() broadcastService?: BroadcastService;

  minDate: string;
  maxDate: string;
  presaleDate: Date;
  userSettings: IStorageUserSettings;
  shopPRroMode = ShopPRroMode;

  UA_MONTHS = UA_MONTHS_GENITIVE.map((month) => month.toLowerCase());
  MAX_STRING_LENGTH = MAX_STRING_LENGTH;
  MAX_CURRENCY_SUM_VALUE = MAX_CURRENCY_SUM_VALUE;
  DOT = DOT_KEY;
  BACKSPACE = BACKSPACE_KEY;

  numpad = [
    [7, 8, 9],
    [4, 5, 6],
    [1, 2, 3],
    [0, DOT_KEY, BACKSPACE_KEY],
  ];

  saleProductsBackup: SaleProduct[];

  totalSum = 0;
  totalSumBackup = 0;
  totalDiscount = 0;
  totalUserDiscount = 0;
  totalUserOverPrice = 0;
  totalFreeCups = 0;
  totalBonusPayment = 0;
  totalBonusAdd = 0;
  totalRound = 0;
  totalPaymentWithoutRound = 0;
  totalPayment = 0;
  totalCash = 0;
  totalCard = 0;
  totalDebt = 0;
  totalChange = 0;

  clientCash = 0;
  bonusMaxSum = 0;
  discountMinSum = 0;
  minPrice = 0;

  totalBonusPaymentText = '';
  totalPaymentText = '';
  totalCardText = '';
  totalCashText = '';
  clientCashText = '';

  loyalty?: ClientBonus;
  loyalties: ClientBonus[];
  tariff: Tariff;

  comment = '';

  isClientFromList = false;
  isJustActivated = false;
  isPRroActive = false;
  isNeedFiscalization = true;
  isInDebt = false;

  dialogInProgress = false;

  searchMode = false;
  searchQuery: string;

  shop: Shop;
  user: User;

  paymentHint = '';
  focus = SaleCalculationFocus;
  activeFocus: SaleCalculationFocus;
  finishButtonStyle = { width: `90%` };

  private requestInProgress = false;

  @HostListener('window:keypress', ['$event'])
  numberKeyEvent(event: KeyboardEvent): void {
    if (
      this.searchMode ||
      this.dialogInProgress ||
      (document.activeElement != null &&
        document.activeElement instanceof HTMLElement &&
        document.activeElement.tagName.toUpperCase() === 'TEXTAREA')
    ) {
      return;
    }

    if (/^[0-9]$/.test(event.key)) {
      this.onKeyPress(event.key);
    } else if (event.key === '.' || event.key === ',') {
      this.onKeyPress(DOT_KEY);
    }
  }

  @HostListener('window:keydown', ['$event'])
  clearKeyEvent(event: KeyboardEvent): void {
    if (this.searchMode || this.dialogInProgress) {
      return;
    }

    if (event.key === 'Backspace') {
      this.onKeyPress(BACKSPACE_KEY);
    }
  }

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private salesService: SalesService,
    private presalesService: PresalesService,
    private utilsService: UtilsService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private tariffService: TariffService,
    private shopService: ShopsService,
    private cachedDataService: CachedDataService,
    private clientsService: ClientsService,
  ) {
    this.userSettings = this.cachedDataService.getUserSettings();
  }

  ngOnInit(): void {
    const minute = DateTime.now().minute;

    this.minDate = DateTime.now()
      .plus({
        minutes: minute > 55 ? 60 - minute : 0,
      })
      .toISO();

    this.maxDate = DateTime.now().plus({ days: 30 }).toISO();

    this.shop = this.cachedDataService.getShop();
    this.user = this.cachedDataService.getUser();

    this.isPRroActive = this.cachedDataService.isPRroActive();
    this.isNeedFiscalization =
      this.isPRroActive && this.shop.prroMode !== ShopPRroMode.Disabled;

    const otherButtonsCount =
      Number(this.shop.prroManualControl && this.isPRroActive) +
      Number(this.shop.presales && !this.presale && !this.saleInDebt);

    this.finishButtonStyle = {
      width: `calc(90% - ${otherButtonsCount * OTHER_BUTTONS_WIDTH}px)`,
    };

    this.saleProductsBackup = [];

    this.saleProducts.forEach((saleProduct) => {
      this.saleProductsBackup.push(cloneDeep(saleProduct));

      this.totalDiscount += saleProduct.personalDiscount ?? 0;
    });

    this.minPrice = this.isNeedFiscalization ? 10 * ONE_KOP : 0;

    this.tariffService
      .getCurrentTariff()
      .then((tariff) => {
        this.tariff = tariff;

        if (this.presale != null || this.saleInDebt != null) {
          this.setSaleData();
        } else if (tariff.discount) {
          this.findClient(false);
        }
      })
      .finally(() => {
        this.activateFocus(this.focus.ClientCash);
      });
  }

  ngOnDestroy(): void {
    if (this.saleProductsBackup.length > 0) {
      this.restoreSaleProducts();
    }

    if (this.broadcastService != null) {
      this.broadcastService.publish({ type: BROADCAST_SALE_CALCULATION });
    }
  }

  showSearchbar(): void {
    this.searchMode = true;

    setTimeout(() => {
      if (this.searchbar) {
        this.searchbar.setFocus();
      }
    }, 100);
  }

  clearSearchbar(): void {
    this.searchMode = false;
    this.searchQuery = '';

    setTimeout(() => (this.loyalties = []), 200);
  }

  toggleShowCommentMode(): void {
    this.userSettings.showCommentInPayment =
      !this.userSettings.showCommentInPayment;

    this.cachedDataService.setUserSettings(this.userSettings);
  }

  async dateTimeChange(): Promise<void> {
    if (this.requestInProgress) {
      return;
    }

    await this.startRequest('payment.component:presale');

    const presale = new Presale();

    presale.completeBy = this.presaleDate;
    presale.cashless =
      this.totalCard > 0 && this.totalCash > 0
        ? null
        : Boolean(this.totalCard > 0);

    presale.presaleProducts = [];

    this.saleProducts.forEach((saleProduct) => {
      presale.presaleProducts.push(cloneDeep(saleProduct));
    });

    if (this.loyalty != null) {
      presale.clientId = this.loyalty.clientId;
      presale.client = new Client();

      presale.client.id = this.loyalty.client.id;
      presale.client.name = this.loyalty.client.name;
      presale.client.mobilePhone = this.loyalty.client.mobilePhone;
    }

    if (this.comment != null && this.comment > '') {
      presale.comment = this.comment;
    }

    if (this.clientCash > 0 && this.totalChange > 0) {
      presale.providedCash = this.clientCash;
    }

    presale.roundSum = this.totalRound;
    presale.paymentSum = this.totalPayment;
    presale.cashSum = this.totalCash;
    presale.cardSum = this.totalCard;
    presale.changeSum = this.totalChange;
    presale.costSum = this.totalSum;
    presale.discountSum =
      this.totalDiscount + this.totalFreeCups + this.totalBonusPayment;

    presale.totalDiscount = this.totalDiscount;
    presale.totalFreeCups = this.totalFreeCups;
    presale.totalBonus = this.totalBonusPayment;

    this.presalesService
      .createPresale(presale)
      .then(async () => {
        remove(this.saleProducts);
        remove(this.saleProductsBackup);

        await this.modalCtrl.dismiss(
          { isSuccess: true },
          '',
          SALE_CALCULATION_DIALOG_ID,
        );
      })
      .finally(async () => {
        await this.finishRequest('payment.component:presale');
      });
  }

  private async startRequest(loadingId: string): Promise<void> {
    this.requestInProgress = true;

    await this.loadingService.presentCustomPreloader(loadingId);
  }

  private async finishRequest(loadingId: string): Promise<void> {
    this.requestInProgress = false;

    await this.loadingService.dismiss(loadingId);
  }

  dateTimeCancel(): void {
    //
  }

  async filterList(): Promise<void> {
    if (this.searchQuery?.length < 3) {
      return;
    }

    const isOnline = await this.utilsService.isOnline();

    this.clientsService
      .findForApp({ query: this.searchQuery }, { forceRefresh: isOnline })
      .subscribe((loyalties) => {
        this.loyalties = loyalties.filter(
          (d) =>
            d.client.name
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            d.client.mobilePhone.includes(this.searchQuery),
        );
      });
  }

  activateFocus(item: SaleCalculationFocus): void {
    if (
      item === this.focus.Payment &&
      (this.loyalty != null || !this.user?.changeSaleSum)
    ) {
      return;
    }

    this.isJustActivated = true;
    this.activeFocus = item;

    switch (this.activeFocus) {
      case SaleCalculationFocus.Bonus:
        this.checkBonusModeError();

        this.paymentHint = `Введіть кількість бонусів, яка буде списана з рахунку клієнта (максимум ${formatNumber(
          Math.min(this.bonusMaxSum, this.loyalty?.bonus ?? 0),
          'uk_UA',
          '1.2-2',
        )} ₴)`;

        this.totalBonusPaymentText = formatNumber(
          this.totalBonusPayment,
          'uk_UA',
          '1.0-2',
        );

        break;

      case SaleCalculationFocus.Payment:
        this.paymentHint = `Введіть бажану суму продажу (мінімум ${formatNumber(
          this.discountMinSum,
          'uk_UA',
          '1.2-2',
        )} ₴)`;

        if (Math.abs(this.totalRound) > 0) {
          this.totalPayment = this.totalPaymentWithoutRound;
        }

        this.totalPaymentText = formatNumber(
          this.totalPayment,
          'uk_UA',
          '1.0-2',
        );

        break;

      case SaleCalculationFocus.Card:
        this.paymentHint =
          'Змініть суму оплати карткою<br>' +
          'або натисніть Готівка для зміни типу оплати';

        if (this.totalCard === 0) {
          this.resetCash();
        }

        this.totalCardText = formatNumber(this.totalCard, 'uk_UA', '1.0-2');

        break;

      case SaleCalculationFocus.Cash:
        this.paymentHint =
          'Введіть суму оплати готівкою<br>' +
          'або натисніть Картка для зміни типу оплати';

        if (this.totalCash === 0) {
          this.resetCard();
        }

        this.totalCashText = formatNumber(this.totalCash, 'uk_UA', '1.0-2');

        break;

      case SaleCalculationFocus.ClientCash:
        this.paymentHint = 'Введіть суму отриманої готівки';

        this.clientCashText = formatNumber(this.clientCash, 'uk_UA', '1.0-2');

        break;

      default:
        break;
    }

    this.calcPayment();
  }

  private checkBonusModeError(): void {
    if (this.shop.bonusPercent === 0 && !this.isGradationBonusSystem()) {
      this.toastService.presentError(
        'Неможливо використати бонуси',
        'Відсоток накопичення бонусів рівний 0.\nЗверніться до менеджера або адміністратора для встановлення % накопичення у налаштуваннях торгової точки (секція "Налаштування акцій") в адмін-панелі',
      );
    }
  }

  onKeyPress(key: number | string): void {
    let value = this.getCurrentActiveValue();

    switch (key) {
      case BACKSPACE_KEY:
        if (value.length > 1) {
          value = this.processBackspace(value);
          break;
        }

        value = '0';
        break;

      case DOT_KEY:
        if (value.includes(DOT_KEY)) {
          break;
        }

        value += DOT_KEY;
        break;

      default:
        if (value === '0') {
          value = key.toString();
          break;
        }

        if (this.isJustActivated) {
          value = '';
        }

        if (
          value.includes(DOT_KEY) &&
          value.length - value.indexOf(DOT_KEY) > 2
        ) {
          break;
        }

        if (Number(value) > this.MAX_CURRENCY_SUM_VALUE) {
          break;
        }

        value += key.toString();
        break;
    }

    this.isJustActivated = false;

    this.setActiveValues(value);
  }

  private processBackspace(value: string): string {
    value = value.substring(0, value.length - 1);

    if (value.endsWith('.') || value.endsWith(',')) {
      value = value.substring(0, value.length - 1);
    }

    return value;
  }

  private getCurrentActiveValue(): string {
    switch (this.activeFocus) {
      case SaleCalculationFocus.Bonus:
        return this.totalBonusPaymentText;

      case SaleCalculationFocus.Payment:
        return this.totalPaymentText;

      case SaleCalculationFocus.Card:
        return this.totalCardText;

      case SaleCalculationFocus.Cash:
        return this.totalCashText;

      case SaleCalculationFocus.ClientCash:
        return this.clientCashText;

      default:
        return '';
    }
  }

  private setActiveValues(value: string): void {
    const numberValue = Number(value.replace(DOT_KEY, '.'));
    let checkCardSum = false;

    switch (this.activeFocus) {
      case SaleCalculationFocus.Bonus:
        this.totalBonusPaymentText = value;
        this.totalBonusPayment = numberValue;

        const maxBonus = Math.min(this.bonusMaxSum, this.loyalty?.bonus ?? 0);

        if (this.totalBonusPayment >= maxBonus) {
          this.totalBonusPayment = maxBonus;
          this.totalBonusPaymentText = formatNumber(
            this.totalBonusPayment,
            'uk_UA',
            '1.0-2',
          );
        }

        checkCardSum = true;
        break;

      case SaleCalculationFocus.Payment:
        this.restoreSaleProducts();

        this.totalPaymentText = value;

        if (numberValue >= this.discountMinSum) {
          this.totalUserDiscount = round(this.totalSumBackup - numberValue, 2);
        } else {
          this.totalUserDiscount = 0;
          this.totalUserOverPrice = 0;
        }

        checkCardSum = true;
        break;

      case SaleCalculationFocus.Card:
        if (numberValue > this.totalPaymentWithoutRound) {
          this.resetCash();
        } else {
          this.totalCardText = value;
          this.totalCard = numberValue;
        }

        break;

      case SaleCalculationFocus.Cash:
        if (numberValue > this.totalPayment) {
          this.resetCard();
        } else {
          this.totalCashText = value;
          this.totalCash = numberValue;
          this.totalCard = this.totalPaymentWithoutRound - this.totalCash;
        }

        break;

      case SaleCalculationFocus.ClientCash:
        this.clientCashText = value;
        this.clientCash = numberValue;

        break;

      default:
        return;
    }

    if (checkCardSum) {
      this.calcPaymentAndCheckCardSum();
    } else {
      this.calcPayment();
    }
  }

  resetBonus(): void {
    this.checkBonusModeError();

    if (this.totalBonusPayment === 0) {
      this.totalBonusPayment = Math.min(
        this.bonusMaxSum,
        this.loyalty?.bonus ?? 0,
      );
    } else {
      this.totalBonusPayment = 0;

      this.restoreSaleProducts();
    }

    this.totalBonusPaymentText = formatNumber(
      this.totalBonusPayment,
      'uk_UA',
      '1.0-2',
    );

    this.calcPaymentAndCheckCardSum();
  }

  resetUserDiscount(): void {
    this.totalUserDiscount = 0;
    this.totalUserOverPrice = 0;

    this.restoreSaleProducts();
    this.calcPaymentAndCheckCardSum();
  }

  resetCard(): void {
    this.totalCard = 0;
    this.totalCardText = '0';

    this.calcPayment();
  }

  resetCash(): void {
    this.totalCard = this.totalPaymentWithoutRound;
    this.totalCardText = formatNumber(this.totalCard, 'uk_UA', '1.0-2');

    this.calcPayment();
  }

  resetClientCash(): void {
    this.clientCash = 0;
    this.clientCashText = '0';

    this.calcPayment();
  }

  async setDelivery(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: DeliveryDialog,
      componentProps: {},
      backdropDismiss: false,
    });

    this.dialogInProgress = true;

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data.comment != null && data.comment > '') {
      this.comment = data.comment;
    }

    this.dialogInProgress = false;
  }

  clearComment(): void {
    this.comment = '';
  }

  async createPresale(): Promise<void> {
    if (await this.invalidInvoice()) {
      return;
    }

    this.dateTime.open();
  }

  async finish(needFiscalization: boolean = true): Promise<void> {
    if (await this.invalidInvoice()) {
      return;
    }

    if (this.requestInProgress) {
      return;
    }

    this.dialogInProgress = true;

    const cashlessPayment =
      this.totalCard > 0
        ? await this.salesService.cashlessPaymentDialog(this.totalCard)
        : undefined;

    this.dialogInProgress = false;

    if (this.totalCard > 0 && cashlessPayment == null) {
      return;
    }

    await this.startRequest('payment.component:finish');

    const sale = new Sale();

    sale.cashless = this.cashless();

    sale.saleProducts = [];

    this.saleProducts.forEach((saleProduct) => {
      sale.saleProducts.push(cloneDeep(saleProduct));
    });

    this.setClient(sale);
    this.setComment(sale);

    sale.roundSum = this.totalRound;
    sale.paymentSum = this.totalPayment;
    sale.debtSum = this.totalDebt;
    sale.cashSum = this.totalCash;
    sale.cardSum = this.totalCard;
    sale.clientCash = this.clientCash;
    sale.changeSum = this.totalChange;
    sale.costSum = this.totalSum;
    sale.discountSum =
      this.totalDiscount + this.totalFreeCups + this.totalBonusPayment;

    sale.totalDiscount = this.totalDiscount;
    sale.totalFreeCups = this.totalFreeCups;
    sale.totalBonus = this.totalBonusPayment;
    sale.totalBonusAdd = this.totalBonusAdd;
    sale.totalBonusBalance += this.totalBonusAdd - this.totalBonusPayment;

    this.setPayments(sale, cashlessPayment);
    this.setPresale(sale);
    this.setDebtSale(sale);

    sale.calcTotalTaxes();

    this.salesService
      .createSale(sale, CheckDocumentSubType.CheckGoods, { needFiscalization })
      .then(async () => {
        if (this.presale == null) {
          remove(this.saleProducts);
          remove(this.saleProductsBackup);
        }

        await this.modalCtrl.dismiss(
          {
            isSuccess: true,
            sale: this.shop.printCheck ? sale : null,
          },
          '',
          SALE_CALCULATION_DIALOG_ID,
        );
      })
      .finally(async () => {
        await this.finishRequest('payment.component:finish');
      });
  }

  private cashless(): boolean | null {
    if (this.totalCard > 0 && this.totalCash > 0) {
      return null;
    }

    return Boolean(this.totalCard > 0);
  }

  private setClient(sale: Sale): void {
    if (this.loyalty != null) {
      sale.clientId = this.loyalty.clientId;

      sale.client = new Client();

      sale.client.id = this.loyalty.client.id;
      sale.client.name = this.loyalty.client.name;

      sale.totalBonusBalance = this.loyalty.bonus;
    }
  }

  private setComment(sale: Sale): void {
    if (this.comment != null && this.comment > '') {
      sale.comment = this.comment;
    }
  }

  private setPayments(sale: Sale, cashlessPayment?: SalePayment): void {
    sale.salePayments = [];

    if (Math.abs(this.totalDebt) > 0) {
      const cashPayment = new SalePayment();

      cashPayment.method = PayFormCode.InDebt;
      cashPayment.amount = this.totalDebt;

      sale.salePayments.push(cashPayment);
    }

    if (Math.abs(this.totalCash) > 0) {
      const cashPayment = new SalePayment();

      cashPayment.method = PayFormCode.Cash;
      cashPayment.amount = this.totalCash;

      if (Math.abs(this.clientCash) > 0 && Math.abs(this.totalChange) > 0) {
        cashPayment.providedCash = this.clientCash;
      }

      sale.salePayments.push(cashPayment);
    }

    if (Math.abs(this.totalCard) > 0) {
      const cardPayment = new SalePayment();

      cardPayment.method = PayFormCode.Card;
      cardPayment.amount = this.totalCard;

      if (cashlessPayment != null) {
        cardPayment.acquirerName = cashlessPayment.acquirerName;
        cardPayment.acquirerTransactionId =
          cashlessPayment.acquirerTransactionId;
        cardPayment.authCode = cashlessPayment.authCode;
        cardPayment.deviceId = cashlessPayment.deviceId;
        cardPayment.epzDetails = cashlessPayment.epzDetails;
        cardPayment.paymentSystem = cashlessPayment.paymentSystem;
        cardPayment.posTransactionDate = cashlessPayment.posTransactionDate;
        cardPayment.posTransactionNumber = cashlessPayment.posTransactionNumber;
        cardPayment.transactionId = cashlessPayment.transactionId;
        cardPayment.signVerification = cashlessPayment.signVerification;
        cardPayment.operationType = cashlessPayment.operationType;
      }

      sale.salePayments.push(cardPayment);
    }
  }

  private setPresale(sale: Sale): void {
    if (this.presale != null) {
      sale.presaleId = this.presale.id;
    }
  }

  private setDebtSale(sale: Sale): void {
    if (this.saleInDebt != null) {
      sale.saleInDebtId = this.saleInDebt.id;
    }
  }

  async cancelModal(): Promise<void> {
    await this.modalCtrl.dismiss({ isSuccess: false });
  }

  setSaleData(): void {
    const doc = this.presale != null ? this.presale : this.saleInDebt;

    if (doc == null) {
      return;
    }

    this.totalSum = doc.costSum;
    this.totalSumBackup = doc.costSum;
    this.totalDiscount = doc.totalDiscount;

    this.totalUserDiscount = round(this.totalSumBackup - doc.costSum, 2);

    this.totalFreeCups = doc.totalFreeCups;
    this.totalBonusPayment = doc.totalBonus;
    this.totalBonusAdd = doc.totalBonusAdd;
    this.totalRound = doc.roundSum;

    this.totalPaymentWithoutRound =
      this.totalSum -
      this.totalDiscount -
      this.totalFreeCups -
      this.totalBonusPayment;

    this.totalPayment = doc.paymentSum;
    this.totalCash = doc.cashSum;
    this.totalCard = doc.cardSum;
    this.totalChange = doc.changeSum;

    this.comment = doc.comment;

    if (doc.client != null) {
      this.loyalty = new ClientBonus();

      this.loyalty.clientId = doc.client.id;

      this.loyalty.client = new Client();

      this.loyalty.client.id = doc.client.id;
      this.loyalty.client.mobilePhone = doc.client.mobilePhone;
      this.loyalty.client.name = doc.client.name;

      this.loyalty.bonus = doc.client.bonus ?? 0;
      this.loyalty.balance = doc.client.balance ?? 0;
      this.loyalty.salesBalance = doc.client.salesBalance ?? 0;

      this.isClientFromList = true;
    }

    if (this.presale != null) {
      this.clientCash = this.presale.providedCash;

      this.presale.presaleProducts.forEach((presaleProduct) => {
        const price = presaleProduct.product.price;

        this.bonusMaxSum += presaleProduct.quantity * (price - this.minPrice);
        this.discountMinSum += presaleProduct.quantity * this.minPrice;
      });

      this.calcPayment();
    } else if (this.saleInDebt != null) {
      if (this.loyalty != null && this.clientBonus != null) {
        this.loyalty.client.mobilePhone = this.clientBonus.client.mobilePhone;
        this.loyalty.wholesaleBuyer = this.clientBonus.wholesaleBuyer;
        this.loyalty.personalDiscount = this.clientBonus.personalDiscount;
        this.loyalty.bonus = this.clientBonus.bonus;
        this.loyalty.balance = this.clientBonus.balance;
        this.loyalty.salesBalance = this.clientBonus.salesBalance;
      }

      this.saleInDebt.saleProducts.forEach((saleProduct) => {
        const price = saleProduct.product.price;

        this.bonusMaxSum += saleProduct.quantity * (price - this.minPrice);
        this.discountMinSum += saleProduct.quantity * this.minPrice;
      });

      this.isClientFromList = false;
    }
  }

  findClient(refresh: boolean = true): void {
    this.clientsService.findActiveForApp().subscribe((activeLoyalty) => {
      if (activeLoyalty != null) {
        this.activateClient(activeLoyalty);
      } else if (refresh) {
        this.calcPayment();
      }
    });
  }

  selectClient(loyalty: ClientBonus): void {
    if (this.loyalty != null) {
      this.deactivateClient();
    }

    this.isClientFromList = true;

    this.activateClient(loyalty);
  }

  removeClient(): void {
    if (this.isClientFromList) {
      this.deactivateClient();

      return;
    }

    this.clientsService.removeActiveForApp().subscribe(() => {
      this.deactivateClient();
    });
  }

  private activateClient(loyalty: ClientBonus): void {
    if (Math.abs(this.totalUserDiscount) > 0) {
      this.resetUserDiscount();
    }

    if (loyalty.wholesaleBuyer && this.shop.wholesalePrice) {
      this.loyalty = loyalty;

      this.useClientWholesalePrices();

      return;
    }

    let freeCupsProductIds: number[] = [];

    if (loyalty.personalDiscount === 0 && this.shop.freeCupNumber > 0) {
      this.saleProducts.forEach((saleProduct) => {
        if (saleProduct.product.freeCupActive) {
          saleProduct.freeCup = 0;

          freeCupsProductIds.push(saleProduct.productId);
        }
      });

      freeCupsProductIds = freeCupsProductIds.filter(
        (item, index) => freeCupsProductIds.indexOf(item) === index,
      );
    }

    if (freeCupsProductIds.length > 0) {
      this.shopService
        .getClientFreeCups(this.shop.id, loyalty.clientId, freeCupsProductIds)
        .subscribe((freeCups) => {
          this.useClientOptions(loyalty, freeCups);
        });
    } else {
      this.useClientOptions(loyalty, []);
    }
  }

  private useClientWholesalePrices(): void {
    this.setWholesalePrices();
    this.calcPaymentAndCheckCardSum();
  }

  private useClientOptions(
    loyalty: ClientBonus,
    freeCupsHistory: ClientFreeCup[],
  ): void {
    this.loyalty = loyalty;
    this.totalDiscount = 0;
    this.totalBonusAdd = 0;

    this.saleProducts.forEach((saleProduct) => {
      if (
        this.loyalty?.personalDiscount != null &&
        this.loyalty?.personalDiscount > 0
      ) {
        this.calcPersonalDiscount(saleProduct);
      } else if (
        this.shop.freeCupNumber > 0 &&
        saleProduct.product.freeCupActive &&
        freeCupsHistory.length > 0
      ) {
        this.calcFreeCup(freeCupsHistory, saleProduct);
      }
    });

    this.calcPaymentAndCheckCardSum();
  }

  private deactivateClient(): void {
    this.loyalty = undefined;
    this.totalBonusPayment = 0;
    this.isClientFromList = false;

    this.restoreSaleProducts();
    this.calcPaymentAndCheckCardSum();
  }

  private restoreSaleProducts(): void {
    remove(this.saleProducts);

    this.saleProductsBackup.forEach((saleProduct) => {
      const item = cloneDeep(saleProduct);

      this.saleProducts.push(item);
    });
  }

  private setWholesalePrices(): void {
    this.restoreSaleProducts();

    this.saleProducts.forEach((saleProduct) => {
      saleProduct.price =
        saleProduct.product.wholesalePrice || saleProduct.price;

      saleProduct.fullPrice =
        saleProduct.product.wholesalePrice || saleProduct.fullPrice;

      saleProduct.cost = this.utilsService.cost(
        saleProduct.quantity,
        saleProduct.price,
      );

      saleProduct.fullCost = this.utilsService.cost(
        saleProduct.quantity,
        saleProduct.fullPrice,
      );
    });
  }

  private getPriceAndDiscount(
    saleProduct: SaleProduct,
    discountPercent: number,
  ): { price: number; discount: number } {
    let discount =
      Math.floor(saleProduct.fullPrice * discountPercent) / MAX_PERCENT_VALUE;

    let price = round(saleProduct.fullPrice - discount, 2);

    if (discountPercent === MAX_PERCENT_VALUE && this.isNeedFiscalization) {
      price = 10 * ONE_KOP;
    }

    price = Math.max(0, price);
    discount = round(
      saleProduct.fullCost -
        this.utilsService.cost(saleProduct.quantity, price),
      2,
    );

    return { price, discount };
  }

  private div(value: number, by: number): number {
    return (value - (value % by)) / by;
  }

  private calcPersonalDiscount(saleProduct: SaleProduct): void {
    const { price, discount } = this.getPriceAndDiscount(
      saleProduct,
      this.loyalty?.personalDiscount ?? 0,
    );

    saleProduct.price = price;
    saleProduct.personalDiscount = discount;

    this.totalDiscount += saleProduct.personalDiscount;
  }

  private calcFreeCup(
    freeCupsHistory: ClientFreeCup[],
    saleProduct: SaleProduct,
  ): void {
    const productPrice = saleProduct.fullPrice;
    const currentFreeCupInfo = freeCupsHistory.find(
      (freeCup) => freeCup.productId === saleProduct.productId,
    );

    if (
      currentFreeCupInfo != null &&
      currentFreeCupInfo.cupCount + saleProduct.quantity >=
        this.shop.freeCupNumber
    ) {
      if (saleProduct.quantity > 1) {
        const freeCupCount = Math.max(
          1,
          this.div(saleProduct.quantity, this.shop.freeCupNumber),
        );

        saleProduct.price = round(
          ((saleProduct.quantity - freeCupCount) * productPrice) /
            saleProduct.quantity,
          2,
        );

        saleProduct.freeCup = round(
          saleProduct.quantity * (productPrice - saleProduct.price),
          2,
        );

        this.totalFreeCups += freeCupCount;
      } else {
        const { price, discount } = this.getPriceAndDiscount(
          saleProduct,
          this.shop.freeCupPercent,
        );

        saleProduct.price = price;
        saleProduct.freeCup = round(discount, 2);

        this.totalFreeCups += 1;
      }
    }
  }

  private calcBonusPayment(
    saleProduct: SaleProduct,
    bonusPayment: number,
  ): number {
    const price = saleProduct.fullPrice;

    if (bonusPayment > 0 || this.isInDebt) {
      saleProduct.bonusAdd = 0;

      if (bonusPayment === 0) {
        saleProduct.bonusPayment = 0;
      } else if (price * saleProduct.quantity - bonusPayment >= 0) {
        saleProduct.price = round(
          (price * saleProduct.quantity - bonusPayment) / saleProduct.quantity,
          2,
        );

        saleProduct.bonusPayment =
          saleProduct.quantity * (price - saleProduct.price);

        bonusPayment = 0;
      } else {
        const savePrice = saleProduct.quantity * (price - this.minPrice);

        saleProduct.bonusPayment = savePrice;
        saleProduct.price = this.minPrice;

        bonusPayment -= savePrice;
      }
    } else {
      saleProduct.bonusAdd =
        (price *
          saleProduct.quantity *
          (this.isGradationBonusSystem()
            ? this.getGradationBonusAmount(saleProduct)
            : this.shop.bonusPercent)) /
        MAX_PERCENT_VALUE;

      this.totalBonusAdd += saleProduct.bonusAdd;
    }

    return bonusPayment;
  }

  private calcUserDiscount(saleProduct: SaleProduct, discount: number): number {
    if (discount === 0) {
      saleProduct.personalDiscount = 0;
    } else if (saleProduct.fullPrice * saleProduct.quantity - discount >= 0) {
      saleProduct.price = round(
        (saleProduct.fullPrice * saleProduct.quantity - discount) /
          saleProduct.quantity,
        2,
      );

      saleProduct.personalDiscount = round(
        Math.max(
          0,
          saleProduct.quantity * (saleProduct.fullPrice - saleProduct.price),
        ),
        2,
      );

      if (discount < 0) {
        saleProduct.fullPrice = saleProduct.price;
        saleProduct.fullCost = this.utilsService.cost(
          saleProduct.quantity,
          saleProduct.price,
        );
      }

      discount = 0;
    } else {
      const savePrice =
        saleProduct.quantity * (saleProduct.fullPrice - this.minPrice);

      saleProduct.personalDiscount = savePrice;
      saleProduct.price = this.minPrice;

      discount -= savePrice;
    }

    if (this.totalUserDiscount < 0) {
      this.totalUserOverPrice = Math.abs(this.totalUserDiscount);
      this.totalUserDiscount = 0;
    }

    return discount;
  }

  private calcPaymentAndCheckCardSum(): void {
    this.calcPayment(true);
  }

  private calcPayment(checkCardSum: boolean = false): void {
    let bonusPayment = this.totalBonusPayment;
    let userDiscount = this.totalUserDiscount;

    this.totalSum = 0;
    this.totalDiscount = 0;
    this.totalFreeCups = 0;
    this.totalBonusPayment = 0;
    this.totalBonusAdd = 0;
    this.bonusMaxSum = 0;
    this.discountMinSum = 0;

    this.saleProducts.forEach((saleProduct) => {
      saleProduct.quantity = round(saleProduct.quantity, 3);
      saleProduct.fullPrice = round(saleProduct.fullPrice, 2);
      saleProduct.fullCost = this.utilsService.cost(
        saleProduct.quantity,
        saleProduct.fullPrice,
      );

      this.bonusMaxSum +=
        saleProduct.quantity * (saleProduct.fullPrice - this.minPrice);

      this.discountMinSum += saleProduct.quantity * this.minPrice;

      const isBonusMode = this.isBonusMode(saleProduct);

      if (isBonusMode) {
        bonusPayment = this.calcBonusPayment(saleProduct, bonusPayment);
      } else if (Math.abs(this.totalUserDiscount) > 0) {
        userDiscount = this.calcUserDiscount(saleProduct, userDiscount);
      }

      if (saleProduct.price == null) {
        saleProduct.price = round(saleProduct.fullPrice, 2);
      }

      saleProduct.cost = round(saleProduct.quantity * saleProduct.price, 2);

      saleProduct.discount =
        (saleProduct.personalDiscount ?? 0) +
        (saleProduct.freeCup ?? 0) +
        (saleProduct.bonusPayment ?? 0);

      if (saleProduct.excisePercent != null) {
        saleProduct.exciseAmount = PRroSaleItem.exciseAmount(
          saleProduct.cost,
          saleProduct.excisePercent,
        );
      }

      if (saleProduct.vatPercent != null) {
        saleProduct.vatAmount = PRroSaleItem.vatAmount(
          saleProduct.cost,
          saleProduct.vatPercent,
          saleProduct.exciseAmount ?? 0,
        );
      }

      this.totalSum += saleProduct.fullCost;
      this.totalDiscount += saleProduct.personalDiscount ?? 0;
      this.totalFreeCups += saleProduct.freeCup ?? 0;
      this.totalBonusPayment += saleProduct.bonusPayment ?? 0;
    });

    if (this.totalSumBackup === 0) {
      this.totalSumBackup = this.totalSum;
    }

    this.totalPaymentWithoutRound =
      this.totalSum -
      this.totalDiscount -
      this.totalFreeCups -
      this.totalBonusPayment;

    this.calcTotalCash(checkCardSum);
  }

  private calcTotalCash(checkCardSum: boolean = false): void {
    if (this.isInDebt) {
      this.totalDebt = this.totalPaymentWithoutRound;
      this.totalCard = 0;
      this.totalCash = 0;
      this.totalRound = 0;
      this.clientCash = 0;
    } else {
      if (
        checkCardSum &&
        (this.totalCard > this.totalPaymentWithoutRound || this.totalCash === 0)
      ) {
        this.totalCard = this.totalPaymentWithoutRound;
        this.totalCardText = formatNumber(this.totalCard, 'uk_UA', '1.0-2');
      }

      this.totalCash = this.totalPaymentWithoutRound - this.totalCard;

      this.totalRound = this.totalCash
        ? this.utilsService.getRoundSum(this.totalCash)
        : 0;

      this.totalCash += this.totalRound;
    }

    this.totalPayment = this.totalPaymentWithoutRound + this.totalRound;
    this.totalChange = Math.max(this.clientCash - this.totalCash, 0);

    if (this.broadcastService != null) {
      this.broadcastService.publish({
        type: BROADCAST_SALE_CALCULATION,
        payload: {
          totalSum: this.totalSum,
          totalDiscount:
            this.totalDiscount + this.totalFreeCups + this.totalBonusPayment,
          totalRound: this.totalRound,
          totalPayment: this.totalPayment,
          totalCard: this.totalCard,
          totalCash: this.totalCash,
          clientCash: this.clientCash,
          totalChange: this.totalChange,
        },
      });
    }

    const tempTotalPaymentText = formatNumber(
      this.totalPayment,
      'uk_UA',
      '1.0-2',
    );

    const tempTotalCashText = formatNumber(this.totalCash, 'uk_UA', '1.0-2');

    this.totalPaymentText =
      this.totalPaymentText.endsWith(DOT_KEY) &&
      !tempTotalPaymentText.includes(DOT_KEY)
        ? `${tempTotalPaymentText}${DOT_KEY}`
        : tempTotalPaymentText;

    this.totalCashText =
      this.totalCashText.endsWith(DOT_KEY) &&
      !tempTotalCashText.includes(DOT_KEY)
        ? `${tempTotalCashText}${DOT_KEY}`
        : tempTotalCashText;
  }

  private isBonusMode(saleProduct: SaleProduct): boolean {
    return Boolean(
      this.loyalty != null &&
        this.tariff.discount &&
        (this.shop.bonusPercent > 0 || this.isGradationBonusSystem()) &&
        !(
          this.loyalty?.wholesaleBuyer ||
          this.loyalty?.personalDiscount > 0 ||
          (this.shop.freeCupNumber > 0 && saleProduct.product.freeCupActive)
        ),
    );
  }

  setDebt(): void {
    this.isInDebt = !this.isInDebt;

    this.calcPayment();
  }

  private async invalidInvoice(): Promise<boolean> {
    const wrongAmountName = this.getWrongAmountName();

    if (wrongAmountName !== '') {
      const overMaxAmountAlert = await this.alertCtrl.create({
        header: 'Помилка формування чеку',
        message: `Одне або декілька значень введених сум перевищує максимально допустиме<br><br>
            Перевірте значення "<b>${wrongAmountName}</b>"`,
        buttons: [{ text: 'Закрити', role: 'close' }],
      });

      await overMaxAmountAlert.present();

      return true;
    }

    if (this.totalCash > 0) {
      return this.utilsService.cashLimit(this.totalCash);
    }

    return false;
  }

  private getWrongAmountName(): string {
    return this.totalBonusPayment > MAX_CURRENCY_SUM_VALUE
      ? 'Бонуси'
      : this.totalPayment > MAX_CURRENCY_SUM_VALUE
      ? 'До оплати'
      : this.totalCard > MAX_CURRENCY_SUM_VALUE
      ? 'Картка'
      : this.totalCash > MAX_CURRENCY_SUM_VALUE
      ? 'Готівка'
      : this.clientCash > MAX_CURRENCY_SUM_VALUE
      ? 'Отримано'
      : '';
  }

  private isGradationBonusSystem(): boolean {
    return (
      this.shop.bonusPercentLowLimit > 0 ||
      this.shop.bonusPercentMediumLimit > 0 ||
      this.shop.bonusPercentHighLimit > 0
    );
  }

  private getGradationBonusAmount(saleProduct: SaleProduct): number {
    if (
      Math.abs(saleProduct.personalDiscount ?? 0) > 0 ||
      Math.abs(saleProduct.freeCup ?? 0) > 0 ||
      Math.abs(saleProduct.bonusPayment ?? 0) > 0 ||
      this.loyalty == null
    ) {
      return 0;
    }

    const salesBalance = this.loyalty.salesBalance;

    if (salesBalance > this.shop.bonusPercentHighLimit) {
      return this.shop.bonusPercentHigh;
    }

    if (salesBalance > this.shop.bonusPercentMediumLimit) {
      return this.shop.bonusPercentMedium;
    }

    if (salesBalance > this.shop.bonusPercentLowLimit) {
      return this.shop.bonusPercentLow;
    }

    return this.shop.bonusPercent;
  }

  loyaltyCaption(loyalty: ClientBonus): string {
    return loyalty.wholesaleBuyer
      ? '(оптовий)'
      : loyalty.personalDiscount
      ? `(знижка до ${loyalty.personalDiscount} %)`
      : `(${formatNumber(loyalty.bonus, 'uk_UA', '1.2-2')} ₴ бонусів)`;
  }
}
