import { PRRO_NAME } from '../../../settings.const';
import { ROW_LENGTH_36 } from '../receipt-printer.const';
import { SERVICE_DOC_AMOUNT_CAPTION } from '../receipt.const';

import { PrintReceipt } from './print-receipt.model';

export class PrintPRroServiceDoc extends PrintReceipt {
  id: number;
  date: string;

  companyName: string;
  shopName: string;
  shopAddress: string;
  IPN: string;
  EDRPOU: string;

  name: string;
  amount: string;

  isFiscal: boolean;

  prroOnlineStatus: string;
  prroOnlineStatusRow: string;

  prroLocalNumber: string;
  prroFiscalNumber: string;
  prroFiscalNumberRow: string;

  checkLocalNumber: string;
  checkFiscalNumber: string;
  checkFiscalNumberRow: string;

  checkType: string;

  receipt(): string[] {
    const receipt: string[] = [];

    if (this.companyName > '') {
      receipt.push(...this.alignToCenter(`${this.companyName}`));
    }

    receipt.push(...this.alignToCenter(`${this.shopName}`, { isBold: true }));
    receipt.push(...this.alignToCenter(`${this.shopAddress}`));

    if (this.IPN > '') {
      receipt.push(...this.alignToCenter(this.IPN));
    } else if (this.EDRPOU > '') {
      receipt.push(...this.alignToCenter(this.EDRPOU));
    }

    receipt.push(...this.alignToCenter(this.name, { isBold: true }));

    this.addTwoColumnItemToReceipt(
      SERVICE_DOC_AMOUNT_CAPTION,
      this.amount,
      receipt,
    );

    receipt.push(this.rowDevider());

    if (this.isFiscal) {
      if (this.rowLength < ROW_LENGTH_36) {
        receipt.push(...this.alignToLeft(`${this.checkFiscalNumberRow}`));

        receipt.push(...this.alignToLeft(this.date));
      } else {
        this.addTwoColumnItemToReceipt(
          `${this.checkFiscalNumberRow}`,
          this.date,
          receipt,
        );
      }

      this.addTwoColumnItemToReceipt(
        this.prroFiscalNumberRow,
        this.prroOnlineStatus,
        receipt,
      );

      receipt.push(...this.alignToCenter(this.checkType));
    } else {
      receipt.push(...this.alignToCenter(this.date));
    }

    receipt.push(...this.alignToCenter(PRRO_NAME));

    return receipt;
  }
}
