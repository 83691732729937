import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import {
  MAX_CURRENCY_VALUE,
  MIN_CURRENCY_VALUE,
} from '../../../core/constants/form-validations.const';
import { WEIGHT_AMOUNT } from '../../../core/constants/product.const';
import { Measurement } from '../../../core/enum/measurement.enum';
import { WeightProductMeasurement } from '../../../core/enum/weight-product-measurement.enum';
import { ICategoryWithSubcategory } from '../../../core/interfaces/i-category-with-subcategory';
import { ILinkedItem } from '../../../core/interfaces/linked-item.interface';
import { IngredientCategory } from '../../../core/models/ingredient-category.model';
import { Ingredient } from '../../../core/models/ingredient.model';
import { CachedDataService } from '../../../core/services/cached-data.service';
import { IngredientCategoryService } from '../../../core/services/resources/ingredient-category.service';
import { IngredientsService } from '../../../core/services/resources/ingredients.service';
import { ToastService } from '../../../core/services/toast.service';
import { CategoriesService } from '../../../shop/categories/categories.service';
import { Product } from '../../../shop/products/product.model';
import { SubcategoriesService } from '../../../shop/subcategories/subcategories.service';
import { Subcategory } from '../../../shop/subcategories/subcategory.model';

@Component({
  selector: 'bk-new-product-dialog',
  templateUrl: './new-product.dialog.html',
  styleUrls: ['./new-product.dialog.scss'],
})
export class NewProductDialog implements OnInit {
  readonly measurements: string[] = Object.values(Measurement);

  MIN_CURRENCY_VALUE = MIN_CURRENCY_VALUE;
  MAX_CURRENCY_VALUE = MAX_CURRENCY_VALUE;

  categories: ICategoryWithSubcategory[];
  subcategory: Subcategory;

  product = new Product();
  linkedItems: ILinkedItem[] = [];

  ingredientMeasurements = Measurement;
  ingredientCategories: IngredientCategory[] = [];
  ingredient = new Ingredient();

  title: string;
  amount: string;

  productExpanded = false;

  get selectionCount(): number {
    return this.linkedItems.reduce(
      (count, item) => (count += Boolean(item.selected) ? 1 : 0),
      0,
    );
  }

  get pricePlaceholder(): string {
    return `Ціна${this.product.weightProduct ? ` за ${this.amount}` : ''}, ₴`;
  }

  constructor(
    private modalCtrl: ModalController,
    private cachedDataService: CachedDataService,
    private ingredientsService: IngredientsService,
    private ingredientCategoryService: IngredientCategoryService,
    private categoriesService: CategoriesService,
    private subcategoriesService: SubcategoriesService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    const shop = this.cachedDataService.getShop();

    this.ingredient.measurement = Measurement.Unit;

    this.ingredientCategoryService
      .find({ companyId: shop.companyId })
      .subscribe((ingredientCategories: IngredientCategory[]) => {
        this.ingredientCategories = ingredientCategories;
      });
  }

  toggleProductExpanded(): void {
    this.categoriesService
      .findWithSubcategories(this.cachedDataService.getShopId())
      .subscribe((categories) => {
        this.categories = categories;
      });

    this.productExpanded = !this.productExpanded;
  }

  updateAmountType(): void {
    if (
      this.ingredient.measurement === Measurement.Kilogram ||
      this.ingredient.measurement === Measurement.Litre ||
      this.ingredient.measurement === Measurement.Metre
    ) {
      this.product.weightProduct = true;
    } else {
      this.product.weightProduct = false;
    }

    switch (this.ingredient.measurement) {
      case Measurement.Kilogram:
        this.amount = WeightProductMeasurement.Gram;
        break;

      case Measurement.Gram:
        this.amount = WeightProductMeasurement.Gram;
        break;

      case Measurement.Litre:
        this.amount = WeightProductMeasurement.Millilitre;
        break;

      case Measurement.Millilitre:
        this.amount = WeightProductMeasurement.Millilitre;
        break;

      case Measurement.Metre:
        this.amount = WeightProductMeasurement.Millimetre;
        break;

      default:
        this.amount = this.ingredient.measurement;
        break;
    }

    this.amount = `${WEIGHT_AMOUNT} ${this.amount}`;
  }

  categoryChanged(): void {
    this.subcategoriesService
      .find({ linkedById: this.subcategory.id })
      .subscribe((linkedSubcategories) => {
        this.linkedItems = [];

        linkedSubcategories.forEach((linkedSubcategory) => {
          const linkedSubcategoryItem = {
            id: linkedSubcategory.id,
            name: linkedSubcategory.name,
            enabled: true,
            shopId: linkedSubcategory.shopId,
            shopName: linkedSubcategory.shop?.name ?? '',
            selected: true,
          };

          if (linkedSubcategory.shopId !== this.cachedDataService.getShopId()) {
            this.linkedItems.push(linkedSubcategoryItem);
          }
        });

        this.updateAmountType();
      });
  }

  async save(): Promise<void> {
    let params: Partial<any> = {};

    if (this.productExpanded) {
      this.product.shopId = this.cachedDataService.getShopId();
      this.product.subcategoryId = this.subcategory.id;

      this.ingredient.product = this.product;

      if (this.selectionCount > 0) {
        const linkedProducts: Product[] = [];

        this.linkedItems
          .filter((li) => li.selected)
          .forEach((linkedItem) => {
            const linkedProduct = new Product();

            Reflect.deleteProperty(linkedProduct, 'productIngredients');
            Reflect.deleteProperty(linkedProduct, 'linkedProducts');
            Reflect.deleteProperty(linkedProduct, 'enabled');

            linkedProduct.shopId = linkedItem.shopId;
            linkedProduct.price = linkedItem.price ?? this.product.price ?? 0;

            linkedProducts.push(linkedProduct);
          });

        params = { ...params, linkedProducts: JSON.stringify(linkedProducts) };
      }
    }

    this.ingredientsService
      .create(this.ingredient, params)
      .subscribe(async (item: Ingredient) => {
        this.toastService.present(
          this.productExpanded ? 'Найменування створено' : 'Сировину створено',
        );

        await this.modalCtrl.dismiss();
      });
  }

  async cancel(): Promise<void> {
    await this.modalCtrl.dismiss({ success: false });
  }
}
