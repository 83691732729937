import { ClientSource } from './client-source.enum';

export class Client {
  id: number;
  name: string;
  lastName: string;
  middleName: string;
  mobilePhone: string;
  email: string;
  birthday: Date;
  source: ClientSource;

  companyId: number;
  shopId: number;
  password: string;

  // Add field
  bonus?: number;
  balance?: number;
  salesBalance?: number;
}
