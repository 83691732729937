import { Component } from '@angular/core';

import { LABEL_PRINTER_REFRESH } from '../../../core/constants/events.const';
import { IStorageUserSettings } from '../../../core/interfaces/storage-user-setting.interface';
import { CachedDataService } from '../../../core/services/cached-data.service';
import { Events } from '../../../core/services/events.service';
import { UtilsService } from '../../../core/services/utils.service';
import { PrinterConnectionMethod } from '../printer-connection-method.enum';

import {
  BARCODE_CODE128,
  BARCODE_EAN_13,
  DEFAULT_BARCODE_FORMAT,
  DEFAULT_PAPER_FORMAT,
  DEFAULT_SETTINGS,
  MAX_MARGIN,
  PAPER_23_X_14,
  PAPER_40_X_25,
  PAPER_60_X_40,
} from './label-printer.const';
import { LabelPrinterService } from './label-printer.service';

@Component({
  selector: 'bk-label-printer',
  templateUrl: './label-printer.component.html',
  styleUrls: ['./label-printer.component.scss'],
})
export class LabelPrinterComponent {
  readonly printerConnectionMethod = PrinterConnectionMethod;

  userSettings: IStorageUserSettings;
  isAndroidApp: boolean;
  isDesktop: boolean;

  connectionMethods: PrinterConnectionMethod[] = [
    PrinterConnectionMethod.None,
    PrinterConnectionMethod.Browser,
  ];

  paperFormats = [PAPER_23_X_14, PAPER_40_X_25, PAPER_60_X_40];
  barcodeFormats = [BARCODE_EAN_13, BARCODE_CODE128];

  settings = DEFAULT_SETTINGS;
  MAX_MARGIN = MAX_MARGIN;

  additionOptions = false;

  constructor(
    private events: Events,
    private cachedDataService: CachedDataService,
    private utilsService: UtilsService,
    private labelPrinterService: LabelPrinterService,
  ) {
    this.userSettings = this.cachedDataService.getUserSettings();
    this.isAndroidApp = this.utilsService.isAndroidApp();
    this.isDesktop = this.utilsService.isDesktop();

    if (this.isDesktop) {
      this.connectionMethods.push(...[PrinterConnectionMethod.QZTray]);
    } else if (this.isAndroidApp) {
      this.connectionMethods.push(
        ...[
          PrinterConnectionMethod.Bluetooth,
          PrinterConnectionMethod.USB,
          PrinterConnectionMethod.WiFi,
        ],
      );
    }
  }

  async ionViewWillEnter(): Promise<void> {
    this.settings = await this.labelPrinterService.getSettings();

    if (this.settings.connectionMethod === PrinterConnectionMethod.None) {
      return;
    }

    this.refreshSettingsObjects();
  }

  private refreshSettingsObjects(): void {
    this.settings.paperFormat =
      this.paperFormats.find(
        (p) =>
          p.height === this.settings.paperFormat.height &&
          p.width === this.settings.paperFormat.width,
      ) || DEFAULT_PAPER_FORMAT;

    this.settings.barcodeFormat =
      this.barcodeFormats.find(
        (b) => b.name === this.settings.barcodeFormat.name,
      ) || DEFAULT_BARCODE_FORMAT;
  }

  async updateSettings(): Promise<void> {
    this.refreshSettingsObjects();

    await this.setSettings();
    await this.updateSaleServiceOptions();
  }

  private async updateSaleServiceOptions(): Promise<void> {
    const status = await this.labelPrinterService.status();

    this.events.publish(LABEL_PRINTER_REFRESH, {
      isAvailible: status.isPrinterAvailable,
      isAutoPrintMode: status.isAutoPrintAfterSale,
    });
  }

  async setSettings(): Promise<void> {
    await this.labelPrinterService.setSettings(this.settings);
  }
}
