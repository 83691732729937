import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { create } from 'xmlbuilder2';
import { XMLBuilder } from 'xmlbuilder2/lib/interfaces';

import { PayFormCode } from './enums/pay-form-code.enum';
import { PayFormName } from './enums/pay-form-name.enum';
import { PRroCheckHeadData } from './interfaces/p-rro-check-head-data.interface';
import { SalePayment } from './interfaces/sale-payment.interface';
import { PRroCheck } from './models/p-rro-check.model';
import { PRroReturnCheck } from './models/p-rro-return-check.model';
import { PRroSaleCheck } from './models/p-rro-sale-check.model';
import { PRroSaleTax } from './models/p-rro-sale-tax.model';
import { PRroServiceCheck } from './models/p-rro-service-check.model';
import { PRroZReportPayForm } from './models/p-rro-z-report-pay-form.model';
import { PRroZReport } from './models/p-rro-z-report.model';

const PRRO_DOC_VERSION = 1;

@Injectable({
  providedIn: 'root',
})
export class FSCOFormatService {
  //#region Z-report
  getZReportXML(prro: PRroCheckHeadData, zRepData: PRroZReport): string {
    const zRepXML = this.createXML('ZREP', 'zrep01.xsd');
    const zRepHead = zRepXML.ele('ZREPHEAD');

    this.addDocHead(prro, zRepHead, zRepData);

    // Підсумки реалізації
    const zRepRealiz = zRepXML.ele('ZREPREALIZ');

    // Загальна сума (15.2 цифри)
    zRepRealiz.ele('SUM').txt(zRepData.realizSum.toFixed(2));

    // Заокруглення (15.2 цифри) (наприклад, 0.71)
    zRepRealiz.ele('RNDSUM').txt(zRepData.realizRoundSum.toFixed(2));

    // Загальна сума без заокруглення (15.2 цифри) (наприклад, 1000.71)
    zRepRealiz.ele('NORNDSUM').txt(zRepData.realizNoRoundSum.toFixed(2));

    // Кількість чеків (числовий)
    zRepRealiz.ele('ORDERSCNT').txt(zRepData.realizCount.toString());

    if (zRepData.realizPayments.length > 0) {
      const zRepRealizPayForms = zRepRealiz.ele('PAYFORMS');

      zRepData.realizPayments.forEach((payForm, index) => {
        this.addZReportPayForm(zRepRealizPayForms, index + 1, payForm);
      });
    }

    if (zRepData.realizTaxes.length > 0) {
      const zRepRealizTaxes = zRepRealiz.ele('TAXES');

      zRepData.realizTaxes.forEach((tax, index) => {
        this.addTax(zRepRealizTaxes, index + 1, tax);
      });
    }

    // Підсумки повернення
    const zRepReturn = zRepXML.ele('ZREPRETURN');

    zRepReturn.ele('SUM').txt(zRepData.returnSum.toFixed(2));

    // Заокруглення (15.2 цифри) (наприклад, 0.71)
    zRepReturn.ele('RNDSUM').txt(zRepData.returnRoundSum.toFixed(2));

    // Загальна сума без заокруглення (15.2 цифри) (наприклад, 1000.71)
    zRepReturn.ele('NORNDSUM').txt(zRepData.returnNoRoundSum.toFixed(2));

    // Кількість чеків (числовий)
    zRepReturn.ele('ORDERSCNT').txt(zRepData.returnCount.toString());

    if (zRepData.returnPayments.length > 0) {
      const zRepReturnPayForms = zRepReturn.ele('PAYFORMS');

      zRepData.returnPayments.forEach((payForm, index) => {
        this.addZReportPayForm(zRepReturnPayForms, index + 1, payForm);
      });
    }

    if (zRepData.returnTaxes.length > 0) {
      const zRepReturnTaxes = zRepReturn.ele('TAXES');

      zRepData.returnTaxes.forEach((tax, index) => {
        this.addTax(zRepReturnTaxes, index + 1, tax);
      });
    }

    // Підсумки
    const zRepBody = zRepXML.ele('ZREPBODY');

    // Службове внесення//Отримання авансу/Отримання підкріплення (15.2 цифри)
    zRepBody.ele('SERVICEINPUT').txt(zRepData.serviceInput.toFixed(2));

    // Службова видача/Інкасація (15.2 цифри)
    zRepBody.ele('SERVICEOUTPUT').txt(zRepData.serviceOutput.toFixed(2));

    // convert the XML tree to string
    return zRepXML.end();
  }

  private addZReportPayForm(
    zRepPayForms: XMLBuilder,
    index: number,
    payForm: PRroZReportPayForm,
  ): void {
    zRepPayForms
      .ele('ROW', { ROWNUM: String(index) })
      .ele('PAYFORMCD') // Код форми оплати (числовий): 0–Готівка, 1–Банківська картка, 2-Попередня оплата, 3-Кредит, ...
      .txt(payForm.code.toString())
      .up()
      .ele('PAYFORMNM') // Найменування форми оплати (64 символи)
      .txt(payForm.name)
      .up()
      .ele('SUM') // Сума оплати (15.2 цифри)
      .txt(payForm.sum.toFixed(2));
  }

  //#region Check
  getCheckXML(prro: PRroCheckHeadData, checkData: PRroCheck): string {
    const checkXML = this.createXML('CHECK', 'check01.xsd');
    const checkHead = checkXML.ele('CHECKHEAD');

    checkHead.ele('DOCTYPE').txt(checkData.checkType.toString());

    if (
      checkData instanceof PRroServiceCheck ||
      checkData instanceof PRroReturnCheck
    ) {
      checkHead.ele('DOCSUBTYPE').txt(checkData.checkSubType.toString());
    }

    this.addDocHead(prro, checkHead, checkData);

    if (checkData instanceof PRroServiceCheck) {
      // Підсумок по чеку
      const checkTotal = checkXML.ele('CHECKTOTAL');

      this.addCheckTotal(checkTotal, checkData);
    }

    if (
      checkData instanceof PRroSaleCheck ||
      checkData instanceof PRroReturnCheck
    ) {
      // Реалізація
      const checkPayForms = checkXML.ele('CHECKPAY');

      checkData.payments.forEach((payment, index) => {
        this.addCheckPayForm(
          checkPayForms,
          index + 1,
          payment,
          checkData.remainsSum,
        );
      });

      // Податки/Збори
      if (checkData.taxes.length > 0) {
        const checkTax = checkXML.ele('CHECKTAX');

        this.addCheckTax(checkTax, checkData);
      }

      // Продажі
      const checkBody = checkXML.ele('CHECKBODY');

      this.addCheckBody(checkBody, checkData);
    }

    return checkXML.end();
  }

  private addCheckTotal(
    checkTotal: XMLBuilder,
    checkData: PRroServiceCheck,
  ): void {
    if (checkData instanceof PRroSaleCheck) {
      checkTotal.ele('SUM').txt((checkData.paymentSum + 0).toFixed(2));

      if (Math.abs(checkData.roundSum) > 0) {
        checkTotal.ele('RNDSUM').txt((-1 * checkData.roundSum).toFixed(2)); // Заокруглення (15.2 цифри)
        checkTotal
          .ele('NORNDSUM')
          .txt((checkData.totalSum - checkData.discountSum).toFixed(2)); // Загальна сума до заокруглення (15.2 цифри)
      }

      if (Math.abs(checkData.discountSum) > 0) {
        checkTotal
          .ele('DISCOUNTSUM') // Загальна сума знижки (15.2 цифри)
          .txt(checkData.discountSum.toFixed(2));
      }
    } else {
      checkTotal.ele('SUM').txt(checkData.totalSum.toFixed(2));
    }
  }

  private addCheckPayForm(
    checkPayForms: XMLBuilder,
    rowNumber: number,
    data: SalePayment,
    remainsSum: number = 0,
  ): void {
    let payName = '';

    switch (data.method) {
      case PayFormCode.Cash:
        payName = PayFormName.Cash;
        break;
      case PayFormCode.Card:
        payName = PayFormName.Card;
        break;
    }

    const rowNode = checkPayForms.ele('ROW', { ROWNUM: String(rowNumber) });

    rowNode
      .ele('PAYFORMCD') // Код форми оплати (числовий): 0–Готівка, 1–Банківська картка, 2-Попередня оплата, 3-Кредит, ...
      .txt(String(data.method))
      .up()
      .ele('PAYFORMNM') // Найменування форми оплати (64 символи)
      .txt(payName)
      .up()
      .ele('SUM') // Сума оплати (15.2 цифри)
      .txt(data.amount.toFixed(2))
      .up()
      .ele('PROVIDED') // Сума внесених коштів (15.2 цифри)
      .txt(
        (data.method === PayFormCode.Card
          ? data.amount
          : data.providedCash ?? data.amount
        ).toFixed(2),
      );

    if (data.method === PayFormCode.Cash && Math.abs(remainsSum) > 0) {
      rowNode
        .ele('REMAINS') // Решта (не зазначається, якщо решта відсутня) (15.2 цифри)
        .txt(remainsSum.toFixed(2));
    } else if (
      data.method === PayFormCode.Card &&
      data.deviceId != null &&
      data.epzDetails != null &&
      data.paymentSystem != null
    ) {
      const paySysRowNode = rowNode
        .ele('PAYSYS') // Платіжні системи
        .ele('ROW', { ROWNUM: String(1) });

      paySysRowNode
        .ele('NAME') // Найменування платіжної системи (текст)
        .txt(data.paymentSystem)
        .up()
        .ele('ACQUIRENM') // Найменування еквайра торговця (текст)
        .txt(data.acquirerName ?? '');

      if (data.acquirerTransactionId != null) {
        paySysRowNode
          .ele('ACQUIRETRANSID') // Ідентифікатор транзакції, що надається еквайром та ідентифікує операцію в платіжній системі (128 символів)
          .txt(data.acquirerTransactionId);
      }

      if (data.posTransactionNumber != null) {
        if (data.posTransactionDate != null) {
          paySysRowNode
            .ele('POSTRANSDATE') // POS-термінал. Дата та час транзакції (ддммррррггххсс)
            .txt(this.formatDate(data.posTransactionDate, 'ddMMyyyyHHmmss'));
        }

        paySysRowNode
          .ele('POSTRANSNUM') // POS-термінал. Номер чека транзакції (128 символів)
          .txt(data.posTransactionNumber);
      }

      paySysRowNode
        .ele('DEVICEID') // Ідентифікатор платіжного пристрою (128 символів)
        .txt(data.deviceId)
        .up()
        .ele('EPZDETAILS') // Реквізити електронного платіжного засобу (128 символів)
        .txt(data.epzDetails);

      if (data.authCode != null) {
        paySysRowNode
          .ele('AUTHCD') // Код авторизації (64 символи)
          .txt(data.authCode);
      }

      paySysRowNode
        .ele('SUM') // Сума оплати (15.2 цифри)
        .txt(data.amount.toFixed(2));
    }
  }

  private addCheckTax(checkTax: XMLBuilder, checkData: PRroSaleCheck): void {
    checkData.taxes.forEach((tax, index) => {
      this.addTax(checkTax, index + 1, tax);
    });
  }

  private addCheckBody(checkBody: XMLBuilder, checkData: PRroSaleCheck): void {
    checkData.items.forEach((item, index) => {
      const bodyItem = checkBody.ele('ROW', { ROWNUM: String(index + 1) });

      bodyItem
        .ele('CODE') // Внутрішній код товару (64 символи)
        .txt(String(item.code));

      if (item.UKTZED != null && item.UKTZED > '') {
        bodyItem
          .ele('UKTZED') // Код товару згідно з УКТЗЕД (15 цифр)
          .txt(item.UKTZED);
      }

      bodyItem
        .ele('NAME') // Найменування товару, послуги або операції (текст)
        .txt(item.name)
        .up()
        .ele('UNITNM') // Найменування одиниці виміру (64 символи)
        .txt(item.unit)
        .up()
        .ele('AMOUNT') // Кількість/об’єм товару (15.3 цифри)
        .txt(item.quantity.toFixed(3))
        .up()
        .ele('PRICE') // Ціна за одиницю товару (15.2 цифри)
        .txt(item.price.toFixed(2));

      if (item.letters != null && item.letters > '') {
        bodyItem
          .ele('LETTERS') // Літерні позначення видів і ставок податків/зборів (15 символів)
          .txt(item.letters);
      }

      bodyItem
        .ele('COST') // Сума операції (15.2 цифри)
        .txt(item.cost.toFixed(2));

      if (Math.abs(item.discountSum) > 0) {
        bodyItem
          .ele('DISCOUNTTYPE') // Тип знижки/націнки (1 символ): 0–Сумова, 1–Відсоткова
          .txt(item.discountType.toString())
          .up()
          .ele('DISCOUNTSUM') // Загальна сума знижки (15.2 цифри)
          .txt(item.discountSum.toFixed(2));
      }

      if (item.exciseLabel != null && item.exciseLabel > '') {
        const exciseLabels = bodyItem.ele('EXCISELABELS'); // Акцизні марки

        item.exciseLabel.split(';').forEach((exciseLabel, exciseLabelIndex) => {
          exciseLabels
            .ele('ROW', { ROWNUM: String(exciseLabelIndex + 1) })
            .ele('EXCISELABEL') // Серія та номер марки акцизного податку
            .txt(exciseLabel);
        });
      }
    });
  }

  //#region Common XML
  private createXML(rootNodeName: string, xsdSchema: string): XMLBuilder {
    return create({ version: '1.0', encoding: 'windows-1251' }).ele(
      rootNodeName,
      {
        'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
        'xsi:noNamespaceSchemaLocation': xsdSchema,
      },
    );
  }

  private addDocHead(
    prro: PRroCheckHeadData,
    rootNode: XMLBuilder,
    docData: PRroZReport | PRroCheck,
  ): void {
    docData.localNumber = prro.lastDocNumber + 1;

    // Унікальний ідентифікатор документа (GUID)
    rootNode.ele('UID').txt(docData.uId);

    // ЄДРПОУ/ДРФО/№ паспорта продавця (10 символів)
    rootNode.ele('TIN').txt(prro.companyEDRPOU);

    // Податковий номер або Індивідуальний номер платника ПДВ (12 символів)
    if (prro.companyIPN != null && prro.companyIPN > '') {
      rootNode.ele('IPN').txt(prro.companyIPN);
    }

    // Найменування продавця (256 символів)
    rootNode.ele('ORGNM').txt(prro.companyName);

    // Найменування точки продажу (256 символів)
    rootNode.ele('POINTNM').txt(prro.shopName);

    // todo: Адреса точки продажу (256 символів)
    rootNode.ele('POINTADDR').txt(prro.shopAddress);

    // Дата операції (ddmmyyyy)
    rootNode.ele('ORDERDATE').txt(this.formatDate(docData.date, 'ddMMyyyy'));

    // Час операції (hhmmss)
    rootNode.ele('ORDERTIME').txt(this.formatDate(docData.date, 'HHmmss'));

    // Локальний номер документа (128 символів)
    rootNode.ele('ORDERNUM').txt(docData.localNumber.toString());

    // Локальний номер реєстратора розрахункових операцій (64 символи)
    rootNode.ele('CASHDESKNUM').txt(prro.localNumber.toString());

    // Фіскальний номер реєстратора розрахункових операцій (128 символів)
    rootNode.ele('CASHREGISTERNUM').txt(prro.fiscalNumber.toString());

    if (docData instanceof PRroReturnCheck) {
      // tslint:disable-next-line: no-commented-code
      // if (docData.returnPrroNumber != null) {
      //   rootNode.ele('ORDERRETCASHREGNUM').txt(docData.returnPrroNumber.toString());
      // }
      //
      // rootNode.ele('ORDERRETDATE').txt(this.formatDate(docData.returnSaleDate, 'ddMMyyyy'));
      rootNode.ele('ORDERRETNUM').txt(docData.returnTaxNumber);
    }

    // ПІБ касира (128 символів)
    // rootNode.ele('CASHIER').txt((docData.cashier ? docData.cashier : this.subject?.commonName) ?? '');

    if (docData instanceof PRroCheck) {
      // Посилання на графічне зображення найменування або логотипу виробника (256 символів)
      rootNode
        .ele('LOGOURL')
        .txt('https://cashbox.in.ua/assets/images/logo_prro.png');
    }

    // Версія формату документа (числовий)
    rootNode.ele('VER').txt(PRRO_DOC_VERSION.toString());

    if (prro.testMode) {
      docData.testing = true;

      rootNode.ele('TESTING').txt('true');
    }
  }

  private addTax(
    rootNode: XMLBuilder,
    rowNumber: number,
    tax: PRroSaleTax,
  ): void {
    const rowNode = rootNode.ele('ROW', { ROWNUM: String(rowNumber) });

    rowNode
      .ele('TYPE') // Код виду податку/збору (числовий): 0-ПДВ,1-Акциз,2-ПФ...
      .txt(String(tax.type))
      .up()
      .ele('NAME') // Найменування виду податку/збору (64 символи)
      .txt(tax.name)
      .up()
      .ele('LETTER') // Літерне позначення виду і ставки податку/збору (А,Б,В,Г,...) (1 символ)
      .txt(tax.letter)
      .up()
      .ele('PRC') // Відсоток податку/збору (15.2 цифри)
      .txt(tax.prc.toFixed(2))
      .up()
      .ele('TURNOVER') // Сума для розрахування податку/збору (15.2 цифри)
      .txt(tax.turnover.toFixed(2))
      .up()
      .ele('TURNOVERDISCOUNT') // Сума обсягів для розрахування податку/збору з урахуванням знижки (15.2 цифри)
      .txt(tax.turnoverDiscount.toFixed(2))
      .up()
      .ele('SOURCESUM') // Вихідна сума для розрахування податку/збору (15.2 цифри)
      .txt(tax.sourceSum.toFixed(2))
      .up()
      .ele('SUM') // Сума податку/збору (15.2 цифри)
      .txt(tax.sum.toFixed(2));
  }

  private formatDate(date: Date, format: string): string {
    return DateTime.fromJSDate(date).toFormat(format);
  }
}
