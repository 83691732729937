import { PRRO_NAME } from '../../../settings.const';
import { ROW_LENGTH_36 } from '../receipt-printer.const';
import {
  CASH_REMAIN_CAPTION,
  DISCOUNT_SUM_CAPTION,
  LAST_DOC_FISCL_NUMBER_CAPTION,
  LAST_DOC_LOCAL_NUMBER_CAPTION,
  REALIZ_COUNT_CAPTION,
  REALIZ_SUM_CAPTION,
  RETURN_COUNT_CAPTION,
  RETURN_SUM_CAPTION,
  ROUND_SUM_CAPTION,
  SERVICE_INPUT_CAPTION,
  SERVICE_OUTPUT_CAPTION,
  SHIFT_FINISH_CAPTION,
  SHIFT_START_CAPTION,
} from '../receipt.const';

import { PrintPRroZReportPayment } from './print-p-rro-z-report-payment.model';
import { PrintPRroZReportTax } from './print-p-rro-z-report-tax.model';
import { PrintReceipt } from './print-receipt.model';

export class PrintPRroZReport extends PrintReceipt {
  date: string;

  companyName: string;
  shopName: string;
  shopAddress: string;
  IPN: string;
  EDRPOU: string;

  reportName: string;
  reportNumber: string;

  prroOnlineStatus: string;
  prroOnlineStatusRow: string;

  prroLocalNumber: string;
  prroFiscalNumber: string;
  prroFiscalNumberRow: string;

  checkLocalNumber: string;
  checkFiscalNumber: string;
  checkFiscalNumberRow: string;

  taxNumber: string;
  fiscalType: string;

  startedAt: string;
  finishedAt: string;

  lastDocLocalNumber: string;
  lastDocFiscalNumber: string;
  discount: string;

  realizCount: string;
  realizCashSum: string;
  realizCardSum: string;
  realizOtherSum: string;
  realizNoRoundSum: string;
  realizRoundSum: string;
  realizSum: string;

  returnCount: string;
  returnCashSum: string;
  returnCardSum: string;
  returnOtherSum: string;
  returnNoRoundSum: string;
  returnRoundSum: string;
  returnSum: string;

  serviceInput: string;
  serviceOutput: string;
  cashRemain: string;

  realizPayments: PrintPRroZReportPayment[] = [];
  returnPayments: PrintPRroZReportPayment[] = [];

  realizTaxes: PrintPRroZReportTax[] = [];
  returnTaxes: PrintPRroZReportTax[] = [];

  receipt(): string[] {
    const receipt: string[] = [];

    if (this.companyName > '') {
      receipt.push(...this.alignToCenter(`${this.companyName}`));
    }

    receipt.push(...this.alignToCenter(`${this.shopName}`, { isBold: true }));
    receipt.push(...this.alignToCenter(`${this.shopAddress}`));

    if (this.IPN > '') {
      receipt.push(...this.alignToCenter(this.IPN));
    } else if (this.EDRPOU > '') {
      receipt.push(...this.alignToCenter(this.EDRPOU));
    }

    const isFiscal = this.taxNumber > '';

    receipt.push(...this.alignToCenter(this.reportName, { isBold: true }));

    this.addTwoColumnItemToReceipt(
      SHIFT_START_CAPTION,
      this.startedAt,
      receipt,
    );

    if (this.finishedAt > '') {
      this.addTwoColumnItemToReceipt(
        SHIFT_FINISH_CAPTION,
        this.finishedAt,
        receipt,
      );
    }

    receipt.push(this.rowDevider());

    this.addTwoColumnItemToReceipt(
      REALIZ_COUNT_CAPTION,
      this.realizCount,
      receipt,
    );

    this.addTwoColumnItemToReceipt(
      REALIZ_SUM_CAPTION,
      this.realizSum,
      receipt,
      { isBold: true },
    );

    this.addTwoColumnItemToReceipt(
      ROUND_SUM_CAPTION,
      this.realizRoundSum,
      receipt,
    );

    this.realizPayments.forEach((payment) => {
      this.addReportPaymentToReceipt(payment, receipt);
    });

    this.realizTaxes.forEach((tax) => {
      this.addReportTaxToReceipt(tax, receipt);
    });

    receipt.push(this.rowDevider());

    this.addTwoColumnItemToReceipt(
      RETURN_COUNT_CAPTION,
      this.returnCount,
      receipt,
    );

    this.addTwoColumnItemToReceipt(
      RETURN_SUM_CAPTION,
      this.returnSum,
      receipt,
      { isBold: true },
    );

    this.addTwoColumnItemToReceipt(
      ROUND_SUM_CAPTION,
      this.returnRoundSum,
      receipt,
    );

    this.returnPayments.forEach((payment) => {
      this.addReportPaymentToReceipt(payment, receipt);
    });

    this.returnTaxes.forEach((tax) => {
      this.addReportTaxToReceipt(tax, receipt);
    });

    receipt.push(this.rowDevider());

    this.addTwoColumnItemToReceipt(
      SERVICE_INPUT_CAPTION,
      this.serviceInput,
      receipt,
    );

    this.addTwoColumnItemToReceipt(
      SERVICE_OUTPUT_CAPTION,
      this.serviceOutput,
      receipt,
    );

    receipt.push(this.rowDevider());

    this.addTwoColumnItemToReceipt(
      CASH_REMAIN_CAPTION,
      this.cashRemain,
      receipt,
    );

    receipt.push(this.rowDevider());

    if (isFiscal) {
      this.addTwoColumnItemToReceipt(
        DISCOUNT_SUM_CAPTION,
        this.discount,
        receipt,
        { checkExist: true },
      );

      this.addTwoColumnItemToReceipt(
        LAST_DOC_LOCAL_NUMBER_CAPTION,
        this.lastDocLocalNumber,
        receipt,
        { checkExist: true },
      );

      this.addTwoColumnItemToReceipt(
        LAST_DOC_FISCL_NUMBER_CAPTION,
        this.lastDocFiscalNumber,
        receipt,
        { checkExist: true },
      );

      if (
        this.discount > '' ||
        this.lastDocLocalNumber > '' ||
        this.lastDocFiscalNumber > ''
      ) {
        receipt.push(this.rowDevider());
      }

      if (this.rowLength < ROW_LENGTH_36) {
        receipt.push(...this.alignToLeft(`${this.checkFiscalNumberRow}`));

        receipt.push(...this.alignToLeft(this.date));
      } else {
        this.addTwoColumnItemToReceipt(
          `${this.checkFiscalNumberRow}`,
          this.date,
          receipt,
        );
      }

      this.addTwoColumnItemToReceipt(
        this.prroFiscalNumberRow,
        this.prroOnlineStatus,
        receipt,
      );

      receipt.push(...this.alignToCenter(this.fiscalType));
    } else {
      receipt.push(this.prroFiscalNumberRow);
      receipt.push(...this.alignToCenter(this.date));
    }

    receipt.push(...this.alignToCenter(PRRO_NAME));

    return receipt;
  }

  private addReportPaymentToReceipt(
    payment: PrintPRroZReportPayment,
    receipt: string[],
  ): void {
    this.addTwoColumnItemToReceipt(payment.name, payment.sum, receipt);
  }

  private addReportTaxToReceipt(
    tax: PrintPRroZReportTax,
    receipt: string[],
  ): void {
    this.addTwoColumnItemToReceipt(tax.volume, tax.sourceSum, receipt);
    this.addTwoColumnItemToReceipt(tax.name, tax.sum, receipt);
  }
}
