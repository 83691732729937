import { CheckDocumentSubType } from '../enums/check-document-sub-type.enum';

import { PRroSaleCheck } from './p-rro-sale-check.model';

export class PRroReturnCheck extends PRroSaleCheck {
  /**
   * Фіскальний номер реєстратора розрахункових операцій,
   * з якого проводилася операція продажу товару, що повертається
   *
   * (зазначається тільки для чеків повернення)
   */
  returnPrroNumber?: number;

  /**
   * Дата операції продажу товару, що повертається (ддммрррр)
   *
   * (зазначається тільки для чеків повернення)
   */
  returnSaleDate: Date;

  /**
   * Фіскальний номер чека, для якого здійснюється повернення (128 символів)
   *
   * (зазначається тільки для чеків повернення)
   */
  returnTaxNumber: string;

  constructor(date?: Date) {
    super(date);

    this.checkSubType = CheckDocumentSubType.CheckReturn;
  }
}
