import { PayFormName } from '../../../../p-rro/fsco/enums/pay-form-name.enum';
import { PRRO_NAME } from '../../../settings.const';
import {
  ROW_LENGTH_32,
  ROW_LENGTH_34,
  ROW_LENGTH_36,
} from '../receipt-printer.const';
import {
  AUTH_CODE_CAPTION,
  BONUS_ADD_CAPTION,
  BONUS_BALANCE_CAPTION,
  CASH_PAYMENT_CAPTION,
  CASHLESS_PAYMENT_CAPTION,
  CHECK_NUMBER_CAPTION,
  COST_SUM_CAPTION,
  DISCOUNT_SUM_CAPTION,
  EPZ_CAPTION,
  ESC_POS_NEW_LINE,
  PAYMENT_SUM_CAPTION,
  PAYMENT_SYSTEM_CAPTION,
  PROVIDED_SUM_CAPTION,
  REMAINS_SUM_CAPTION,
  ROUND_SUM_CAPTION,
  RRN_CAPTION,
  THANKS,
  TOTAL_SUM_CAPTION,
} from '../receipt.const';

import { PrintProductItem } from './print-product-item.model';
import { PrintReceipt } from './print-receipt.model';
import { PrintSaleTaxItem } from './print-sale-tax-item.model';

export class PrintSale extends PrintReceipt {
  id: number;
  date: string;

  companyName: string;
  shopName: string;
  shopAddress: string;
  IPN: string;
  IPNRow: string;
  EDRPOU: string;
  EDRPOURow: string;

  isFiscal: boolean;
  prroOnlineStatus: string;
  prroOnlineStatusRow: string;
  prroFiscalNumber: string;
  prroFiscalNumberRow: string;
  prroLocalNumber: string;
  checkFiscalNumber: string;
  checkFiscalNumberRow: string;
  checkLocalNumber: string;
  checkType: string;
  qrData: string;
  qrBase64: string;

  returnSaleId: string;
  returnSaleIdRow: string;
  returnedCheckFiscalNumber: string;
  returnedCheckFiscalNumberRow: string;

  costSum: string;
  totalSum: string;
  discountSum: string;
  roundSum: string;
  paymentSum: string;
  paymentSumRow: string;
  debtSum: string;
  debtSumRow: string;
  cashSum: string;
  cashSumRow: string;
  cardSum: string;
  cardSumRow: string;
  providedSum: string;
  providedSumRow: string;
  remainsSum: string;
  remainsSumRow: string;

  bonusPaymentSum: string;
  bonusAddSum: string;
  bonusBalanceSum: string;

  comment: string;
  shopComment: string;

  acquirerName: string;
  acquirerTransactionId: string;
  authCode: string;
  deviceId: string;
  epzDetails: string;
  paymentSystem: string;
  posOperation: string;
  posTransactionDate: string;
  posTransactionNumber: string;
  transactionId: string;

  products: PrintProductItem[];
  taxes: PrintSaleTaxItem[];

  // Old checks
  userName: string;
  clientName: string;
  personalDiscount: string;
  bonusPayment: string;

  receipt(): string[] {
    const receipt: string[] = [];

    if (this.companyName > '') {
      receipt.push(...this.alignToCenter(`${this.companyName}`));
    }

    receipt.push(...this.alignToCenter(`${this.shopName}`, { isBold: true }));
    receipt.push(...this.alignToCenter(`${this.shopAddress}`));

    if (this.IPN > '') {
      receipt.push(...this.alignToCenter(this.IPNRow));
    } else if (this.EDRPOU > '') {
      receipt.push(...this.alignToCenter(this.EDRPOURow));
    }

    this.products.forEach((product) => {
      this.addProductToReceipt(product, receipt);
    });

    receipt.push(this.rowDevider());

    this.addDiscountAmounts(receipt);

    this.addTwoColumnItemToReceipt(TOTAL_SUM_CAPTION, this.totalSum, receipt, {
      isBold: true,
    });

    this.taxes.forEach((tax) => {
      this.addTwoColumnItemToReceipt(tax.name, tax.amount, receipt);
    });

    this.addRoundAmount(receipt);

    receipt.push(this.rowDevider());

    this.addDebtAmount(receipt);
    this.addCardInfo(receipt);
    this.addCashInfo(receipt);

    this.addBonusInfo(receipt);

    if (this.comment > '') {
      receipt.push(...this.alignToLeft(`${this.comment}`));
      receipt.push(this.rowDevider());
    }

    if (this.shopComment > '') {
      this.shopComment.split(ESC_POS_NEW_LINE).forEach((comment) => {
        receipt.push(...this.alignToCenter(`${comment}`));
      });
    }

    receipt.push(...this.alignToCenter(THANKS));

    if (this.isFiscal) {
      this.addFiscalDataToReceipt(receipt);
    } else if (this.id != null) {
      this.addTwoColumnItemToReceipt(`#${this.id}`, this.date, receipt);

      if (this.returnSaleId > '') {
        receipt.push(...this.alignToCenter(`${this.returnSaleIdRow}`));
      }
    } else {
      receipt.push(...this.alignToCenter(this.date));
    }

    receipt.push(...this.alignToCenter(PRRO_NAME));

    return receipt;
  }

  private addDiscountAmounts(receipt: string[]): void {
    if (this.discountSum === '') {
      return;
    }

    this.addTwoColumnItemToReceipt(COST_SUM_CAPTION, this.costSum, receipt);
    this.addTwoColumnItemToReceipt(
      DISCOUNT_SUM_CAPTION,
      this.discountSum,
      receipt,
    );

    receipt.push(this.rowDevider());
  }

  private addRoundAmount(receipt: string[]): void {
    if (this.roundSum === '') {
      return;
    }

    this.addTwoColumnItemToReceipt(ROUND_SUM_CAPTION, this.roundSum, receipt);
    this.addTwoColumnItemToReceipt(
      PAYMENT_SUM_CAPTION,
      this.paymentSumRow,
      receipt,
      { isBold: true },
    );
  }

  private addDebtAmount(receipt: string[]): void {
    if (this.debtSum === '') {
      return;
    }

    this.addTwoColumnItemToReceipt(
      PayFormName.InDebt,
      `${this.debtSumRow}`,
      receipt,
    );

    receipt.push(this.rowDevider());
  }

  private addCardInfo(receipt: string[]): void {
    if (this.cardSum === '') {
      return;
    }

    this.addTwoColumnItemToReceipt(
      CASHLESS_PAYMENT_CAPTION,
      `${this.cardSumRow}`,
      receipt,
    );
    this.addTwoColumnItemToReceipt(
      PayFormName.Card,
      `${this.cardSumRow}`,
      receipt,
    );

    const paymentTerminalDataExist = this.deviceId > '' || this.epzDetails > '';

    if (this.acquirerName > '' || this.deviceId > '') {
      this.addTwoColumnItemToReceipt(this.acquirerName, this.deviceId, receipt);
    }

    if (paymentTerminalDataExist && this.posOperation > '') {
      receipt.push(`${this.prefix()}${this.posOperation}`);
    }

    if (this.epzDetails > '') {
      this.addTwoColumnItemToReceipt(EPZ_CAPTION, this.epzDetails, receipt);
    }

    if (this.paymentSystem > '') {
      this.addTwoColumnItemToReceipt(
        PAYMENT_SYSTEM_CAPTION,
        this.paymentSystem,
        receipt,
      );
    }

    if (this.authCode > '') {
      this.addTwoColumnItemToReceipt(AUTH_CODE_CAPTION, this.authCode, receipt);
    }

    if (this.acquirerTransactionId > '') {
      this.addTwoColumnItemToReceipt(
        RRN_CAPTION,
        this.acquirerTransactionId,
        receipt,
      );
    }

    if (
      this.posTransactionNumber > '' &&
      this.acquirerTransactionId > '' &&
      this.authCode > ''
    ) {
      this.addTwoColumnItemToReceipt(
        CHECK_NUMBER_CAPTION,
        this.posTransactionNumber,
        receipt,
      );
    }

    receipt.push(this.rowDevider());
  }

  private addCashInfo(receipt: string[]): void {
    if (this.cashSum === '') {
      return;
    }

    this.addTwoColumnItemToReceipt(
      CASH_PAYMENT_CAPTION,
      `${this.cashSumRow}`,
      receipt,
    );

    if (this.remainsSum > '') {
      this.addTwoColumnItemToReceipt(
        PROVIDED_SUM_CAPTION,
        this.providedSumRow,
        receipt,
      );
      this.addTwoColumnItemToReceipt(
        REMAINS_SUM_CAPTION,
        this.remainsSumRow,
        receipt,
      );
    }

    receipt.push(this.rowDevider());
  }

  private addBonusInfo(receipt: string[]): void {
    if (this.bonusAddSum > '') {
      this.addTwoColumnItemToReceipt(
        BONUS_ADD_CAPTION,
        this.bonusAddSum,
        receipt,
      );
    }

    if (this.bonusAddSum > '' || this.bonusPaymentSum > '') {
      this.addTwoColumnItemToReceipt(
        BONUS_BALANCE_CAPTION,
        this.bonusBalanceSum,
        receipt,
      );

      receipt.push(this.rowDevider());
    }
  }

  private addProductToReceipt(
    product: PrintProductItem,
    receipt: string[],
  ): void {
    if (this.isFiscal || product.quantity !== '1') {
      receipt.push(`${this.prefix()}${product.quantityAndPrice}`);
    }

    if (product.uktzed > '') {
      receipt.push(`${this.prefix()}${product.uktzedRow}`);
    }

    if (product.exciseLabel > '') {
      receipt.push(...this.alignToLeft(`${product.exciseLabelRow}`));
    }

    this.addTwoColumnItemToReceipt(product.name, product.sum, receipt);
  }

  private addFiscalDataToReceipt(receipt: any[]): void {
    if (this.isQZMode) {
      receipt.push(
        ...[
          {
            type: 'raw',
            format: 'image',
            flavor: 'base64',
            data: this.qrBase64,
            options: {
              language: 'ESCPOS',
              dotDensity: 'double',
            },
          },
        ],
      );
    } else {
      receipt.push(
        `[C]<qrcode size='${
          this.rowLength === ROW_LENGTH_32
            ? 16
            : this.rowLength === ROW_LENGTH_34
            ? 18
            : 22
        }'>${this.qrData}</qrcode>`,
      );
    }

    if (this.rowLength < ROW_LENGTH_36) {
      receipt.push(...this.alignToLeft(`${this.checkFiscalNumberRow}`));

      receipt.push(...this.alignToLeft(this.date));
    } else {
      this.addTwoColumnItemToReceipt(
        `${this.checkFiscalNumberRow}`,
        this.date,
        receipt,
      );
    }

    this.addTwoColumnItemToReceipt(
      `${this.prroFiscalNumberRow}`,
      this.prroOnlineStatus,
      receipt,
    );

    receipt.push(...this.alignToCenter(this.checkType));

    if (this.returnedCheckFiscalNumber) {
      receipt.push(
        ...this.alignToCenter(`${this.returnedCheckFiscalNumberRow}`),
      );
    }
  }
}
